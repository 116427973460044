import React, { useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import OptionsMenu from "./options.menu";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import PrimaryButton from "../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../app/components/buttons/secondary";
import TertiaryButton from "../../../../app/components/buttons/tertiary";

const TopControls = () => {
  const history = useHistory();
  const { id, planId } = useParams();
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);
  const { clearSimData } = useStudyPlan();

  const handleClick = (e) => {
    setAnchor(e.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleBackToList = () => {
    clearSimData();
    history.push(`/ctpo/study-plans/${id}`);
  };

  const handleEdit = () => {
    clearSimData();
    history.push(`/ctpo/study-plans/${id}/${planId}/review`);
  };

  return (
    <Container>
      <TertiaryButton onClick={handleBackToList}>
        <ChevronLeftIcon />
        Back to Study Plan List
      </TertiaryButton>

      <ButtonContainer>
        <SecondaryButton onClick={handleEdit}>Edit Study Plan</SecondaryButton>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <PrimaryButton onClick={handleClick}>
              Save As <ArrowDropDownIcon />
            </PrimaryButton>
            <OptionsMenu
              open={open}
              anchor={anchor}
              onClose={() => setOpen(false)}
              onBlur={() => setOpen(false)}
            />
          </div>
        </ClickAwayListener>
      </ButtonContainer>
    </Container>
  );
};

export default TopControls;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;
