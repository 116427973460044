import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#0F2D6A",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#00AEEF",
    },
    text: {
      secondary: "#808285",
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

export default theme;
