import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import TopControls from "../controls/top.controls";
import EnrollmentRate from "./overall.parameters/enrollment.rate";
import PrimaryDetails from "./overall.parameters/primary.details";
import StudyPlanTypeOptions from "./overall.parameters/study.plan.type.options";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import validator from "validator";
import CountryEnrollRate from "./overall.parameters/country.enroll.rate";
import CountrySites from "./overall.parameters/country.sites";
import SFDORates from "./overall.parameters/sfdo.rates";
import SFDOPatients from "./overall.parameters/sfdo.patients";
import { useStudyPlan } from "../../app/contexts/study.context";
import PatientEnrollmentDate from "./overall.parameters/patient.enrollment.date";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "../../app/contexts/snackbar.context";
import { useUser } from "../../app/contexts/user.context";
import PrimaryButton from "../../app/components/buttons/primary";
import SecondaryButton from "../../app/components/buttons/secondary";

export default function StudyActualsRemodel() {
  const {
    fetchStudyRemodel,
    updateStudyRemodel,
    studyRemodelData,
    setStudyRemodelData,
    remodelLoading,
    remodelUpdateLoading,
    study,
  } = useStudyPlan();

  const { showSnackbar } = useSnackbar();
  const { id } = useParams();
  const history = useHistory();
  const [formKey, setFormKey] = useState(new Date().getTime());
  const { setURLStudyId } = useUser();

  useEffect(() => {
    setURLStudyId(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchStudyRemodel();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormKey(new Date().getTime());
  }, [remodelLoading]);

  const initialValues = studyRemodelData || {};

  const [formValid, setFormValid] = useState(false);

  const validate = (values) => {
    const errors = {};
    setStudyRemodelData(values);

    if (validator.isEmpty(values.studyPlanName || ""))
      errors.studyPlanName = "Required";
    else {
      let studyPlanNameNotUnique = false;
      // eslint-disable-next-line
      study?.studyPlans?.every((studyPlan, index) => {
        if (
          studyPlan?.studyPlanName?.toLowerCase().trim() ===
          values.studyPlanName?.toLowerCase().trim()
        ) {
          studyPlanNameNotUnique = true;
          return false;
        }
        return true;
      });
      if (studyPlanNameNotUnique) {
        errors.studyPlanName = "Study Plan Name must be unique";
      }
    }
    if (validator.isEmpty(values.studyPlanDescr || ""))
      errors.studyPlanDescr = "Required";
    if (!validator.isNumeric(values.screenPatientsReModel?.toString() || ""))
      errors.screenPatientsReModel = "Required";
    if (!validator.isNumeric(values.enrollPatientsReModel?.toString() || ""))
      errors.enrollPatientsReModel = "Required";
    if (!validator.isNumeric(values.completePatientsReModel?.toString() || ""))
      errors.completePatientsReModel = "Required";

    if (values?.studyPlanType === 1) {
      if (
        values.enrollmentRateSelection === 3 &&
        !validator.isNumeric(values.enrollmentRateOther?.toString() || "")
      ) {
        errors["enrollmentRateOther"] = "Required";
      }
    }

    if (values?.studyPlanType === 2) {
      if (
        values.overallEnrollmentDurationSelection === 2 &&
        validator.isEmpty(
          values.lastPatientEnrolledDateMillisOther?.toString() || "",
        )
      ) {
        errors["lastPatientEnrolledDateMillisOther"] = "Required";
      }
    }

    errors["siteGroups"] = [];
    // eslint-disable-next-line
    values.siteGroups?.forEach((siteGroup, index) => {
      errors["siteGroups"].push({});
      if (values?.studyPlanType === 1) {
        if (
          siteGroup.enrollmentRateSelection === 3 &&
          !validator.isNumeric(siteGroup.enrollmentRateOther?.toString() || "")
        ) {
          errors["siteGroups"][index]["enrollmentRateOther"] = "Required";
        }
      }
      if (
        siteGroup.sitesSelection === 3 &&
        !validator.isNumeric(siteGroup.sitesOther?.toString() || "")
      ) {
        errors["siteGroups"][index]["sitesOther"] = "Required";
      }
    });

    setFormValid(checkFormValid(errors));
    return errors;
  };

  const checkFormValid = (errors) => {
    let valid = true;
    Object.keys(errors || {}).map((key) => {
      if (key === "siteGroups") {
        errors[key].forEach((error) => {
          Object.keys(error).forEach((siteKey) => {
            if (error[siteKey]) valid = false;
          });
        });
      } else {
        if (errors[key]) valid = false;
      }
      return "";
    });

    return valid;
  };

  const onSubmit = async (values) => {
    // Filter out site groups with "Remove Country" selected
    const filteredSiteGroups = values.siteGroups.filter(
      (siteGroup) => siteGroup.sitesSelection !== 4,
    );

    // Create the submission data with the filtered site groups
    const submissionData = {
      ...values,
      siteGroups: filteredSiteGroups,
    };

    let res = await updateStudyRemodel(submissionData).catch((err) => {
      showSnackbar("Remodel submission error.", "error");
    });
    if (res) {
      if (res.data && res.data.length > 0) {
        let remodel = res.data[0];
        showSnackbar("Remodel submitted successfully.", "success");
        history.push(
          `/ctpo/study-plans/${remodel.studyId}/${remodel.studyPlanId}/review`,
        );
      }
    }
  };

  const handleBackStudyActual = () => {
    history.push(`/ctpo/study-actuals/${id}`);
  };

  return (
    <Fragment>
      {remodelLoading && <LinearProgress />}
      {!remodelLoading && (
        <Formik
          key={formKey}
          validate={validate}
          initialValues={initialValues}
        >
          {({ errors, values, touched, setValues }) => (
            <Form>
              <TopControls />
              <PrimaryDetails
                errors={errors}
                touched={touched}
                values={values}
              />
              <StudyPlanTypeOptions
                errors={errors}
                touched={touched}
                setValues={setValues}
                values={values}
              />
              {values?.studyPlanType === 1 && (
                <EnrollmentRate
                  errors={errors}
                  touched={touched}
                  setValues={setValues}
                  values={values}
                />
              )}
              {values?.studyPlanType === 2 && (
                <PatientEnrollmentDate
                  errors={errors}
                  touched={touched}
                  setValues={setValues}
                  values={values}
                />
              )}
              {values?.studyPlanType === 1 && (
                <CountryEnrollRate
                  errors={errors}
                  touched={touched}
                  setValues={setValues}
                  values={values}
                />
              )}
              <CountrySites
                errors={errors}
                touched={touched}
                setValues={setValues}
                values={values}
              />
              <SFDORates
                errors={errors}
                touched={touched}
                setValues={setValues}
                values={values}
              />
              <SFDOPatients
                errors={errors}
                touched={touched}
                setValues={setValues}
                values={values}
              />
              <ButtonContainer>
                <SecondaryButton onClick={handleBackStudyActual}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  disabled={!formValid || remodelUpdateLoading}
                  onClick={() => onSubmit(values)}
                  type="submit"
                >
                  {remodelUpdateLoading ? (
                    <CircularProgress size="1em" />
                  ) : (
                    "Continue"
                  )}
                </PrimaryButton>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin: 1em 0em;
`;
