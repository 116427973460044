import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { COLUMN_HEADERS } from "./table.utils";

import TableCell from "@material-ui/core/TableCell";

const TimelineSummaries = () => {
  const { simulationResults } = useStudyPlan();
  const { timelineTable } = simulationResults;

  return (
    <AccordionTable
      width="50%"
      summary="Timeline Durations"
      columns={COLUMN_HEADERS}
      data={timelineTable}
      renderRow={(item) => (
        <>
          <TableCell
            style={{ borderColor: "var(--Grey-4)" }}
            component="th"
            scope="row"
          >
            <strong>{item.DurationLabel}</strong>
          </TableCell>
          <TableCell
            style={{ borderColor: "var(--Grey-4)" }}
            align="center"
          >
            {item["Duration"] ?? "###"}
          </TableCell>
        </>
      )}
    />
  );
};

export default TimelineSummaries;
