import { Cell, Container, Row } from "..";
import { Accordion } from "../../../../../app/components";
import EditNotesInput from "../edit.notes.input";
import React from "react";

export default function EPriceInfoTable({ data }) {
  const [editState, setEditState] = React.useState({});

  const headerData = [
    { width: "30%", textAlign: "right", label: "" },
    { width: "10%", label: "Visit Sampling SDR/SDV Time (reduced SDR/SDV)" },
    { width: "10%", label: "100% SDR/SDV" },
    {
      width: "10%",
      label: "Data Review (CDA): KRI Review and Study Level Reporting Only",
    },
    { width: "10%", label: "SDR/SDV % Subject Visits" },
    { width: "30%", label: "Notes" },
  ];

  const renderHeader = () => (
    <Row>
      {headerData.map((col, idx) => (
        <Cell
          key={idx}
          variant="header"
          justify={col.textAlign ? col.textAlign : undefined}
          width={col.width}
          style={col.textAlign ? { borderBottom: "none" } : {}}
        >
          {col.label}
        </Cell>
      ))}
    </Row>
  );

  const renderRows = () => {
    return data.map((d, idx) => {
      return (
        <Row>
          <Cell
            variant="header"
            justify="flex-end"
            width="30%"
            style={{ borderBottom: "none" }}
            textAlign="right"
          >
            {d.rowLabel}
          </Cell>
          <Cell width="10%">{d.cellValue1}</Cell>
          <Cell width="10%">{d.cellValue2}</Cell>
          <Cell width="10%">{d.cellValue3}</Cell>
          <Cell width="10%">{d.cellValue4}</Cell>
          <Cell
            style={{ position: "relative" }}
            textAlign="left"
            justify="flex-start"
            width="30%"
          >
            <EditNotesInput
              d={d}
              editState={editState}
              setEditState={setEditState}
            />
          </Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion
      scrollIntoView={true}
      width={"100%"}
      summary={"ePrice Info"}
    >
      <Container>
        {renderHeader()}
        {renderRows()}
      </Container>
    </Accordion>
  );
}
