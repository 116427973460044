import { Cell, Container, Row } from "..";
import { Accordion } from "../../../../../app/components";
import EditNotesInput from "../edit.notes.input";
import React from "react";

const HEADER_DATA = [
  { width: "30%", label: "" },
  { width: "10%", label: "100% (traditional)" },
  { width: "10%", label: "Visit Sampling Modeling" },
  { width: "10%", label: "Difference from Traditional" },
  { width: "40%", label: "Notes" },
];

export default function CRATable({ data }) {
  const [editState, setEditState] = React.useState({});

  const renderHeader = () => (
    <Row>
      {HEADER_DATA.map((col, idx) => (
        <Cell
          key={idx}
          variant="header"
          width={col.width}
          style={col.width === "30%" ? { borderBottom: "none" } : {}}
        >
          {col.label}
        </Cell>
      ))}
    </Row>
  );

  const renderRows = () => {
    return data.map((d, idx) => {
      return (
        <Row>
          <Cell
            variant="header"
            justify="flex-end"
            width="30%"
            style={{ borderBottom: "none" }}
            textAlign="right"
          >
            {d.rowLabel}
          </Cell>
          <Cell width="10%">{d.cellValue1}</Cell>
          <Cell width="10%">{d.cellValue2}</Cell>
          <Cell width="10%">{d.cellValue3}</Cell>
          <Cell
            style={{ position: "relative" }}
            textAlign="left"
            justify="flex-start"
            width="40%"
          >
            <EditNotesInput
              d={d}
              editState={editState}
              setEditState={setEditState}
            />
          </Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion
      scrollIntoView={true}
      width={"100%"}
      summary={"CRA Time - SDR/SDV Time"}
    >
      <Container>
        {renderHeader()}
        {renderRows()}
      </Container>
    </Accordion>
  );
}
