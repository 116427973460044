import React from "react";
import SecondaryButton from "../../../app/components/buttons/secondary";

export const CloseButton = ({ setRemodelViewOpen }) => {
  return (
    <SecondaryButton
      onClick={() => setRemodelViewOpen(false)}
      style={{ width: "fit-content", padding: "5px" }}
    >
      Close
    </SecondaryButton>
  );
};
