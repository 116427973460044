export const headCells = [
  { id: "scheduleOfEventsName", label: "SOE Name", minWidth: "19rem" },
  { id: "scheduleOfEventsDescr", label: "Description", minWidth: "9rem" },
  { id: "protocolVersion", label: "Protocol Version" },
  { id: "protocolDate", label: "Protocol Date", type: "date" },
  { id: "scheduleOfEventsStatus", label: "Status" },
  { id: "studyPlanId", label: "Study Plan" },
  { id: "insertBy", label: "Created By", minWidth: "10rem" },
  { id: "insertDate", label: "Created Date", minWidth: "7rem", type: "date" },
  { id: "updateBy", label: "Last Updated By" },
  { id: "updateDate", label: "Last Updated Date", type: "date" },
  { id: "controls", label: "" },
];
