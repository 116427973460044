import { Cell, Container, Row } from "..";
import { Accordion } from "../../../../../app/components";
import { Edit as EditIcon } from "@material-ui/icons";
import EditNotesInput from "../edit.notes.input";
import { IconButton } from "@material-ui/core";
import React from "react";

const EDITABLE_FIELDS = [
  "CDA Site/Subject Reporting, Average Frequency / Hours to generate",
];

const HEADER_DATA = [
  { width: "30%", textAlign: "right", label: "" },
  { width: "10%", label: "Number" },
  { width: "10%", label: "Hours" },
  { width: "10%", label: "" },
  { width: "40%", label: "Notes" },
];

export default function CDATable({ data, setEditOpen }) {
  const [editState, setEditState] = React.useState({});

  const renderHeader = () => (
    <Row>
      {HEADER_DATA.map((col, idx) => (
        <Cell
          key={idx}
          variant="header"
          justify={col.textAlign ? col.textAlign : undefined}
          width={col.width}
          style={col.textAlign ? { borderBottom: "none" } : {}}
        >
          {col.label}
        </Cell>
      ))}
    </Row>
  );

  const renderRows = () => {
    return data.map((d, idx) => {
      return (
        <Row>
          <Cell
            variant="header"
            justify="flex-end"
            width="30%"
            style={{
              borderBottom: "none",
              position: "relative",
              paddingLeft: "20px",
            }}
            textAlign="right"
          >
            {d.rowLabel}
            {EDITABLE_FIELDS.includes(d.rowLabel?.trim()) && (
              <IconButton
                variant="text"
                color="primary"
                onClick={() => setEditOpen(true)}
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: "4px",
                }}
              >
                <EditIcon style={{ width: "1rem", height: "1rem" }} />
              </IconButton>
            )}
          </Cell>
          <Cell width="10%">{d.cellValue1}</Cell>
          <Cell width="10%">{d.cellValue2}</Cell>
          <Cell width="10%">{d.cellValue3}</Cell>
          <Cell
            style={{ position: "relative" }}
            textAlign="left"
            justify="flex-start"
            width="40%"
          >
            <EditNotesInput
              d={d}
              editState={editState}
              setEditState={setEditState}
            />
          </Cell>
        </Row>
      );
    });
  };

  return (
    <Accordion
      scrollIntoView={true}
      width={"100%"}
      summary={"CDA Subject Review Time"}
    >
      <Container>
        {renderHeader()}
        {renderRows()}
      </Container>
    </Accordion>
  );
}
