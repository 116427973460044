import AppBar from "@material-ui/core/AppBar";
import Controls from "./controls";
import Divider from "@material-ui/core/Divider";
import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const USA_CODE = "USA";

const CountryTabs = (props) => {
  const history = useHistory();
  const { studyPlan } = useStudyPlan();
  const { countries } = studyPlan;
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const tabsClasses = useTabsStyles();

  const tabLabels = makeTabLabels(countries);

  const handleChange = (index) => {
    history.push(`?country=${index}`);
  };

  return (
    <Container id="country-tabs">
      <Controls />
      <Content>
        <AppBar
          position="static"
          color="transparent"
          classes={classes}
        >
          <CountriesTabContainer>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
                color: "var(--Primary-color)",
                marginRight: "1em",
              }}
            >
              Countries
            </span>
            <Tabs
              {...props}
              classes={tabsClasses}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              indicatorColor="primary"
            >
              {tabLabels.map((tab, index) => (
                <Tab
                  classes={tabClasses}
                  label={tab}
                  key={tab}
                  onClick={() => handleChange(index)}
                  id={`scrollable-auto-tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </CountriesTabContainer>
        </AppBar>
        <Divider />
      </Content>
    </Container>
  );
};

export default CountryTabs;

const Content = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const CountriesTabContainer = styled.div`
  display: flex;
  item-align: center;
  align-items: center;
`;

const Container = styled.div``;

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    width: "900px",
  },
}));

const useTabStyles = makeStyles(() => ({
  root: {
    width: "78px",
    minWidth: "78px",
  },
}));

const useTabsStyles = makeStyles(() => ({
  indicator: {
    width: "60px",
    minWidth: "60px",
  },
}));

const makeTabLabels = (countries) =>
  countries
    .sort((a, b) => a.countryCodeISO3.localeCompare(b.countryCodeISO3))
    .map((country) => {
      if (country.countryCodeISO3 === USA_CODE) {
        if (country.siteGroups && country.siteGroups.length > 0) {
          const { siteGroupName } = country.siteGroups[0];
          const labelArr = siteGroupName.replace(/[{()}]/g, "").split(" ");

          return `${labelArr[0]} - ${labelArr[1][0]}`;
        }
        return country.countryCodeISO3;
      }
      return country.countryCodeISO3;
    });
