import { DragDropContext, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useMemo } from "react";

import { ASSESSMENT_TYPES_WITH_SITE_COST } from "./table.utils";
import { Accordion } from "../../../../app/components";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import TableHeader from "./table.header";
import TableRow from "./table.row";
import { getScheduleOfEventsAssessmentTableDefaults } from "../../../../app/contexts/helpers";
import { isNumberNotEmpty } from "../../utils/helpers";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

/*
  This component is responsible for rendering the table of assessments
  It uses the schedule events context to handle the schedule of events.
  It uses the study context to handle the study plan.
*/
export default function AssessmentsTable({
  value,
  country,
  fieldType,
  siteId,
  setTableVisibility,
  tableVisibility,
}) {
  const removeColWidth = value === 0 ? 5 : 0;
  const typeColumnWidth = 22;
  const { id, scheduleId } = useParams();
  const {
    scheduleOfEventsAssessments,
    setScheduleOfEventsAssessmentsChangeLog,
    scheduleOfEventsAssessmentsChangeLog,
    setScheduleOfEventsAssessments,
    setVisitsAndAssessmentsMappingChangeLog,
    fetchSOEAssessments,
    loadingCountrySOE,
    assessmentNames,
    allAssessmentNames,
  } = useScheduleEvents();

  const isCountry = useMemo(() => {
    if (!country.siteGroupId) return false;
    return true;
  }, [country]);

  const columnCount = useMemo(() => (isCountry ? 5 : 14.5), [isCountry]);

  useEffect(() => {
    if (
      isCountry &&
      !scheduleOfEventsAssessments[`${country.siteGroupId}-${siteId}`]
    ) {
      (async () => {
        await fetchSOEAssessments(
          scheduleId,
          id,
          isCountry,
          country.siteGroupId,
          siteId,
        );
      })();
    }
    // eslint-disable-next-line
  }, [isCountry, country, siteId]);

  const addNewRow = () => {
    setScheduleOfEventsAssessments({
      ...scheduleOfEventsAssessments,
      [country.siteGroupId || "study"]: [
        ...scheduleOfEventsAssessments[country.siteGroupId || "study"],
        getScheduleOfEventsAssessmentTableDefaults(
          parseInt(scheduleId || "0"),
          id,
          { assessmentNames: allAssessmentNames },
        ),
      ],
    });
    setScheduleOfEventsAssessmentsChangeLog({
      ...scheduleOfEventsAssessmentsChangeLog,
      [country.siteGroupId || "study"]: { someValue: "some change" },
    });
    setVisitsAndAssessmentsMappingChangeLog({ someValue: "some change" });
  };

  const removeRow = (identifier) => {
    setScheduleOfEventsAssessments({
      ...scheduleOfEventsAssessments,
      [country.siteGroupId || "study"]: [
        ...scheduleOfEventsAssessments[country.siteGroupId || "study"]?.filter(
          (sv) =>
            (isNumberNotEmpty(sv.scheduleOfEventsAssessmentId)
              ? sv.scheduleOfEventsAssessmentId
              : sv.key) !== identifier,
        ),
      ],
    });
    setScheduleOfEventsAssessmentsChangeLog({
      ...scheduleOfEventsAssessmentsChangeLog,
      [country.siteGroupId || "study"]: { someValue: "some change" },
    });
    setVisitsAndAssessmentsMappingChangeLog({ someValue: "some change" });
  };

  const handleAssessmentsValueChange = (name, value, key, type = "number") => {
    let newScheduleAssessments = scheduleOfEventsAssessments[
      (country.siteGroupId && `${country.siteGroupId}-${siteId}`) || "study"
    ]?.map((sv) => {
      if ((sv.scheduleOfEventsAssessmentId || sv.key) === key) {
        if (name === "assessmentType") {
          return {
            ...sv,
            [name]: type === "number" && value ? value : value,
            assessmentNames: assessmentNames
              .filter((an) =>
                ASSESSMENT_TYPES_WITH_SITE_COST.includes(value)
                  ? an.assessmentType === "Site Costs"
                  : an.assessmentType === value,
              )
              .map((a) => a.assessmentName),
            assessmentNameMapping: "",
          };
        } else {
          return {
            ...sv,
            [name]: type === "number" && value ? value : value,
          };
        }
      }
      return sv;
    });

    setScheduleOfEventsAssessments({
      ...scheduleOfEventsAssessments,
      [(country.siteGroupId && `${country.siteGroupId}-${siteId}`) || "study"]:
        newScheduleAssessments,
    });
    if (name !== "load")
      setScheduleOfEventsAssessmentsChangeLog({
        ...scheduleOfEventsAssessmentsChangeLog,
        [(country.siteGroupId && `${country.siteGroupId}-${siteId}`) ||
        "study"]: {
          someValue: "some change",
        },
      });
  };

  const onAssessmentsChange = (name, value, key, type) => {
    handleAssessmentsValueChange(name, value, key, type);
  };

  const renderTableRows = () => {
    return scheduleOfEventsAssessments[
      (country.siteGroupId && `${country.siteGroupId}-${siteId}`) || "study"
    ]?.map((sv, index) => {
      return (
        <TableRow
          fieldType={fieldType}
          removeColWidth={removeColWidth}
          typeColumnWidth={typeColumnWidth}
          key={sv.key || sv.scheduleOfEventsAssessmentId}
          sv={{ ...sv }}
          index={index}
          removeRow={removeRow}
          onAssessmentsChange={onAssessmentsChange}
          value={value}
          assessmentNames={sv.assessmentNames}
          assessments={
            scheduleOfEventsAssessments[country.siteGroupId || "study"]
          }
          columnCount={columnCount}
        />
      );
    });
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (
      destination.draggableId === source.draggableId &&
      destination.index === source.index
    )
      return;

    const newOrderedAssessments = scheduleOfEventsAssessments["study"];
    const reorderedAssessment = newOrderedAssessments[source.index];
    newOrderedAssessments.splice(source.index, 1);
    newOrderedAssessments.splice(destination.index, 0, reorderedAssessment);
    setScheduleOfEventsAssessments({
      ...scheduleOfEventsAssessments,
      study: newOrderedAssessments,
    });
    setScheduleOfEventsAssessmentsChangeLog({
      ...scheduleOfEventsAssessmentsChangeLog,
      [country.siteGroupId || "study"]: {
        someValue: "some change",
      },
    });
  };

  return (
    <>
      {!loadingCountrySOE && (
        <Accordion
          scrollIntoView={true}
          width={"100%"}
          summary={"Assessments"}
          defaultExpanded={tableVisibility.showAssessmentsTable}
          onClick={() => {
            setTableVisibility("showAssessmentsTable");
          }}
        >
          {tableVisibility.showAssessmentsTable && (
            <Container
              key={country.siteGroupId || "study"}
              onClick={(event) => event.stopPropagation()}
            >
              {!isCountry ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Table>
                    <TableHeader
                      value={value}
                      columnCount={columnCount}
                      removeColWidth={removeColWidth}
                      typeColumnWidth={typeColumnWidth}
                      fieldType={fieldType}
                    />
                    <Droppable droppableId="assessments">
                      {(provided) => (
                        <RowsContainer
                          // key={rerenderTable}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {renderTableRows()}
                          {provided.placeholder}
                        </RowsContainer>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              ) : (
                <Table>
                  <TableHeader
                    columnCount={columnCount}
                    value={value}
                    removeColWidth={removeColWidth}
                    typeColumnWidth={typeColumnWidth}
                  />
                  {renderTableRows()}
                </Table>
              )}
              {value === 0 && (
                <ButtonContainer>
                  <Button
                    sx={{ width: "4rem" }}
                    onClick={addNewRow}
                    startIcon={<AddIcon />}
                  >
                    Add Row
                  </Button>
                </ButtonContainer>
              )}
            </Container>
          )}
        </Accordion>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 1rem 0rem;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(p) => (p.noBorder ? "none" : "1px solid var(--Grey-4)")};
  width: 100%;
`;

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
