import { makeStyles, withStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import { IconButton } from "@material-ui/core";
import MuiTableHead from "@material-ui/core/TableHead";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { HEAD_CELLS } from "./constants";

// create a styled table cell with no horizontal padding
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const TableHead = ({
  filterActive,
  setFilterActive,
  orderBy,
  order,
  onRequestSort,
}) => {
  const classes = useStyles();
  const otherClasses = useOtherStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        <StyledTableCell>
          <IconButton onClick={() => setFilterActive(!filterActive)}>
            <FilterListIcon fontSize="small" />
          </IconButton>
        </StyledTableCell>
        {HEAD_CELLS.map(({ id, label, minWidth, width, align = "center" }) => (
          <StyledTableCell
            key={id}
            style={{ minWidth, width }}
            align={align}
            padding="default"
            sortDirection={orderBy === id ? order : false}
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : "asc"}
              onClick={createSortHandler(id)}
            >
              <strong>{label}</strong>
              {orderBy === id && (
                <span className={otherClasses.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              )}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "var(--Grey-1)",
    borderRadius: "10px",
  },
}));

const useOtherStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
