import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import { timelineColors } from "../colors";
import moment from "moment";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { makeStyles } from "@material-ui/core";

const CohortTimelineChart = () => {
  const classes = useStyles();
  const { simulationResults } = useStudyPlan();
  const { cohortTimelineChart: data } = simulationResults;
  const colors = timelineColors;

  const formattedChartData = useMemo(() => {
    if (!data) return [];

    const series = data.reduce((acc, obj) => {
      const cohortName = obj.cohortName;
      const milestone = obj["milestoneName"];
      const startDate = new Date(
        moment(obj["Project Start Date"])
          .add("days", obj["studyDayStart"])
          .format("YYYY-MM-DD"),
      ).getTime();
      const endDate = new Date(
        moment(obj["Project Start Date"])
          .add("days", obj["studyDayStart"] + obj["studyDayDuration"])
          .format("YYYY-MM-DD"),
      ).getTime();

      const existingCohort = acc.find((c) => c.name === milestone);

      if (existingCohort) {
        existingCohort.data.push({
          x: cohortName,
          y: [startDate, endDate],
          customData: { milestone: milestone },
        });
      } else {
        acc.push({
          name: milestone,
          data: [
            {
              x: cohortName,
              y: [startDate, endDate],
              customData: { milestone: milestone },
            },
          ],
        });
      }

      return acc;
    }, []);

    return series;
  }, [data]);
  if (!data) return null;
  const options = {
    chart: {
      height: 1000,
      type: "rangeBar",
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    grid: {
      padding: {
        bottom: 45,
        left: 70,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "60%",
        rangeBarGroupRows: true,
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (val, opts) {
        const label =
          opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
            .customData.milestone;
        return label;
      },
      style: {
        colors: ["#000"],
        fontSize: "10px",
        fontWeight: "normal",
        fontFamily: "var(--system-font)",
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 0,
        borderWidth: 0,
        borderColor: "#000",
        opacity: 0.4,
      },
    },
    title: {
      text: "Cohorts Timeline",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "var(--system-font)",
        color: "#212121",
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "MMM yyyy",
        style: {
          fontSize: "16px",
          colors: "#666666",
          fontFamily: "var(--system-font)",
        },
        rotate: -90, // Rotate the labels by -90 degrees (vertical)
        rotateAlways: true, // Always rotate the labels
      },
    },
    yaxis: {
      labels: {
        minWidth: 0,
        maxWidth: 600,
        style: {
          fontSize: "16px",
          colors: "#666666",
          fontFamily: "var(--system-font)",
        },
      },
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: "solid",
      opacity: 0.8,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      fontSize: "16px",
      fontFamily: "var(--system-font)",
      labels: {
        colors: colors,
        useSeriesColors: true,
      },
    },
    colors: colors,
    interactions: {
      enabled: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        const startDate = moment(data.y[0])
          .add(1, "days")
          .format("DD-MMM-YYYY");
        const endDate = moment(data.y[1]).add(1, "days").format("DD-MMM-YYYY");
        const durationInDays = Math.round(
          (data.y[1] - data.y[0]) / (1000 * 60 * 60 * 24),
        );

        return `<div class="custom-tooltip" style="padding: 10px;">
          <p><strong>${data.customData.milestone}</strong></p>
          <hr/>
          <br/>
          <p>Start: ${startDate}</p>
          <p>End: ${endDate}</p>
          <br/>
          <p>Duration: ${durationInDays} days</p>
        </div>`;
      },
    },
  };

  return (
    <>
      <div className={classes.chartContainer}>
        <Chart
          options={options}
          series={formattedChartData}
          type="rangeBar"
          height={450}
        />
      </div>
      <style>{`
        .apexcharts-menu-item.exportSVG {
          display: none;
        }
      `}</style>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "#002e6e",
    fontSize: "1.2rem",
    fontWeight: "600",
    textAlign: "center",
  },
  chartContainer: {
    width: "80%",
    margin: "0 auto",
    backgroundColor: "#FFF",
    marginBottom: "20px",
  },
}));

export default CohortTimelineChart;
