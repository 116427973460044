import React, { useState, useEffect } from "react";
import { Switch, useLocation, matchPath } from "react-router-dom";
import styled from "styled-components";
import Header from "../common/header";
import Paper from "../common/paper";
import ScheduleEditRoute from "./common/edit.route/schedule";
import SOE from "./soe";
import SOEForecast from "./soe.forecast";

const ScheduleStudyPlan = () => {
  const [showDetailsButton, setShowDetailsButton] = useState(false);
  const location = useLocation();

  const isExactMatch = (path) =>
    matchPath(location.pathname, {
      path: path,
      exact: true,
    });

  useEffect(() => {
    if (isExactMatch("/ctpo/study-plans/:id/soe/:scheduleId/forecast")) {
      setShowDetailsButton(false);
    } else if (isExactMatch("/ctpo/study-plans/:id/soe/:scheduleId")) {
      setShowDetailsButton(true);
    } else {
      setShowDetailsButton(false);
    }
  }, [location]);

  return (
    <Container>
      <Paper>
        <React.Fragment>
          <HeaderContainer>
            <Header showDetailsButton={showDetailsButton} />
          </HeaderContainer>
          <Switch>
            <ScheduleEditRoute
              exact
              path="/ctpo/study-plans/:id/soe/:scheduleId/forecast"
              component={SOEForecast}
            />
            <ScheduleEditRoute
              exact
              path="/ctpo/study-plans/:id/soe/:scheduleId"
              component={SOE}
            />
          </Switch>
        </React.Fragment>
      </Paper>
    </Container>
  );
};

export default ScheduleStudyPlan;

const Container = styled.div`
  min-width: 1300px;
  width: 100%;
  position: sticky;
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 5em;
  z-index: 10000;
  background: var(--Primary-color);
  border-radius: 4px;
  color: var(--White);
  padding: 1em 1em 0 0;
`;
