import React, { useMemo, useState } from "react";

import API from "../../../../app/utils/api";
import BottomControls from "./bottom.controls";
import CountryGlobalMilestones from "./country.global.milestones";
import { CountryHeader } from "../../../../app/components";
import CountryInputs from "./country.inputs";
import Divider from "@material-ui/core/Divider";
import SequentialCohortSetupTable from "../sequential.cohort.setup.table";
import SiteFatigue from "./site.fatigue";
import SiteGroupActivationProfiles from "./site.group.activation.profiles";
import SiteGroupEnrollmentSeasonalVariations from "./site.group.enrollment.seasonal.variations";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import SeparateContainer from "../../../../app/components/separate.Container";

const USA_CODE = "USA";

const CountryPanel = ({
  value,
  id,
  country,
  values,
  hasErrors,
  setValues,
  errors,
  setHasErrors,
}) => {
  const { studyPlan } = useStudyPlan();
  const { cohortType, studyPlanTypeDetail } = studyPlan;
  const [fieldErrors, setFieldErrors] = useState([]);
  const siteGroup = useMemo(() => {
    return country.siteGroups[0];
  }, [country]);
  const sequentialCohort = useMemo(
    () => cohortType === 2 && studyPlanTypeDetail === "Cohort (D)",
    [cohortType, studyPlanTypeDetail],
  );

  if (!country) return;
  const label = makeTabLabels(country);

  const handleUpdate = (update) => API.updateCountry(update);

  if (
    siteGroup === undefined ||
    siteGroup.milestones.length === 0 ||
    siteGroup.parameters.length === 0
  ) {
    return (
      <Typography>
        Study Plan with id {studyPlan.studyPlanId} is experiencing an issue with
        country {country.countryName}(ID:{country.countryId}). Please contact
        service desk.
      </Typography>
    );
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== id}
      id={`scrollable-auto-tabpanel-${id}`}
    >
      {value === id && (
        <Container id="seasonal-variation">
          <SeparateContainer>
            <CountryHeader
              title={label}
              countryId={country.countryId}
              siteGroup={siteGroup}
              studyPlanType={studyPlan.studyPlanType}
              currentEffort={siteGroup.timeSiteIdSize}
              currentTimeframe={siteGroup.timeCountryStartUpSize}
            />
            <CountryInputs
              country={country}
              siteGroup={siteGroup}
              onUpdate={handleUpdate}
              setValues={setValues}
              values={values}
              setFieldErrors={setFieldErrors}
              setHasErrors={setHasErrors}
            />
          </SeparateContainer>
          <br />
          <CountryGlobalMilestones
            country={country}
            siteGroup={siteGroup}
          />
          <br />
          <SiteGroupActivationProfiles
            country={country}
            siteGroup={siteGroup}
            setHasErrors={setHasErrors}
          />
          <br />
          <SiteGroupEnrollmentSeasonalVariations
            country={country}
            siteGroup={siteGroup}
            setHasErrors={setHasErrors}
          />
          <br />
          <SiteFatigue
            country={country}
            siteGroup={siteGroup}
          />

          {sequentialCohort && (
            <>
              <br /> <SequentialCohortSetupTable />
            </>
          )}
          <BottomControls
            errors={errors}
            hasErrors={hasErrors}
            sequentialCohort={sequentialCohort}
          />
        </Container>
      )}
    </div>
  );
};

export default CountryPanel;

const Container = styled.div`
  padding: 1em 0;
  display: grid;
  gap: 1em;
`;

const makeTabLabels = (country) => {
  if (country.siteGroups && country.siteGroups.length > 0) {
    const { siteGroupName, countryName } = country.siteGroups[0];
    const labelArr = siteGroupName.replace(/[{()}]/g, "").split(" ");

    return `${countryName}${labelArr.length > 1 ? ` - ${labelArr[1]}` : ""}`;
  } else {
    return `${country.countryName} - ${country.countryCodeISO3}`;
  }
};
