import { DragDropContext, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useMemo, useState } from "react";

import { Accordion } from "../../../../app/components";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { FIELD_TYPES } from "../constants";
import TableHeader from "./table.header";
import TableRow from "./table.row";
import { getScheduleOfEventsVisitTableDefaults } from "../../../../app/contexts/helpers";
import { isNumberNotEmpty } from "../../utils/helpers";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import Alert from "@material-ui/lab/Alert";

/*
  This component is responsible for rendering the table of visits for a schedule of events.
  It uses the schedule events context to handle the schedule of events.
  It uses the study context to handle the study plan.
*/
export default function VisitsTable({
  value,
  country,
  setEditOpen,
  fieldType,
  siteId,
  setTableVisibility,
  tableVisibility,
}) {
  const removeColWidth = value === 0 ? 5 : 0;
  const { id, scheduleId } = useParams();
  const {
    scheduleOfEventsVisits,
    setScheduleOfEventsVisits,
    setScheduleOfEventsVisitsChangeLog,
    scheduleOfEventsVisitsChangeLog,
    setVisitsAndAssessmentsMappingChangeLog,
    fetchSOEVisits,
    loadingCountrySOE,
    currentSOE,
  } = useScheduleEvents();
  const [showOverheadRequiredAlert, setShowOverheadRequiredAlert] =
    useState(false);
  const [eventVisitIdCounter, setEventVisitIdCounter] = useState(10000);

  const isCountry = useMemo(() => {
    if (!country.siteGroupId) return false;
    return true;
  }, [country]);

  const columnCount = useMemo(() => {
    return country.siteGroupId
      ? 17.5
      : fieldType === FIELD_TYPES.COST
      ? 20
      : fieldType === FIELD_TYPES.VISIT
      ? 18
      : fieldType === FIELD_TYPES.MEDICAL
      ? 24
      : 20;
    // eslint-disable-next-line
  }, [fieldType, isCountry, country]);

  const updateVisitOverheadPct = () => {
    if (
      !scheduleOfEventsVisits ||
      Object.keys(scheduleOfEventsVisits).length === 0
    )
      return;

    const updateVisitsWithOverheadPct = (visits) => {
      const firstVisitWithOverheadPct = visits.find(
        (visit) => visit.visitOverheadPct,
      );
      if (!firstVisitWithOverheadPct) {
        setShowOverheadRequiredAlert(true);
        setTimeout(() => setShowOverheadRequiredAlert(false), 4000);
        return visits;
      }
      const firstVisitOverheadPct = firstVisitWithOverheadPct.visitOverheadPct;
      return visits.map((visit) => ({
        ...visit,
        visitOverheadPct: firstVisitOverheadPct,
      }));
    };

    const updatedScheduleOfEventsVisits = { ...scheduleOfEventsVisits };

    if (!isCountry) {
      if (
        scheduleOfEventsVisits.study &&
        scheduleOfEventsVisits.study.length > 0
      ) {
        updatedScheduleOfEventsVisits.study = updateVisitsWithOverheadPct(
          scheduleOfEventsVisits.study,
        );
      }
    } else {
      Object.keys(scheduleOfEventsVisits).forEach((key) => {
        if (
          key !== "study" &&
          scheduleOfEventsVisits[key] &&
          scheduleOfEventsVisits[key].length > 0
        ) {
          updatedScheduleOfEventsVisits[key] = updateVisitsWithOverheadPct(
            scheduleOfEventsVisits[key],
          );
        }
      });
    }

    setScheduleOfEventsVisits(updatedScheduleOfEventsVisits);
  };

  useEffect(() => {
    if (
      isCountry &&
      !scheduleOfEventsVisits[`${country.siteGroupId}-${siteId}`]
    ) {
      (async () => {
        await fetchSOEVisits(
          scheduleId,
          id,
          isCountry,
          country.siteGroupId,
          siteId,
        );
      })();
    }
    // eslint-disable-next-line
  }, [isCountry, country, siteId]);

  const addNewRow = () => {
    const newId = eventVisitIdCounter + 1;
    setScheduleOfEventsVisits({
      ...scheduleOfEventsVisits,
      [country.siteGroupId || "study"]: [
        ...scheduleOfEventsVisits[country.siteGroupId || "study"],
        {
          ...getScheduleOfEventsVisitTableDefaults(
            parseInt(scheduleId || "0"),
            id,
            {
              visitIntervalType: currentSOE.visitIntervalType,
            },
          ),
          scheduleOfEventsVisitId: newId,
        },
      ],
    });

    setScheduleOfEventsVisitsChangeLog({
      ...scheduleOfEventsVisitsChangeLog,
      [country.siteGroupId || "study"]: { someValue: "seq cohort change" },
    });

    setVisitsAndAssessmentsMappingChangeLog({ someValue: "some change" });

    setEventVisitIdCounter(newId);
  };

  const removeRow = (identifier) => {
    setScheduleOfEventsVisits({
      ...scheduleOfEventsVisits,
      [country.siteGroupId || "study"]: [
        ...scheduleOfEventsVisits[country.siteGroupId || "study"].filter(
          (sv) =>
            (isNumberNotEmpty(sv.scheduleOfEventsVisitId)
              ? sv.scheduleOfEventsVisitId
              : sv.key) !== identifier,
        ),
      ],
    });
    setScheduleOfEventsVisitsChangeLog({
      ...scheduleOfEventsVisitsChangeLog,
      [country.siteGroupId || "study"]: { someValue: "seq cohort change" },
    });
    setVisitsAndAssessmentsMappingChangeLog({ someValue: "some change" });
  };
  const handleVisitsValueChange = (name, value, key, type = "number") => {
    setScheduleOfEventsVisits((prevValues) => {
      const newScheduleVisits = prevValues[
        (country.siteGroupId && `${country.siteGroupId}-${siteId}`) || "study"
      ]?.map((sv) => {
        if ((sv.scheduleOfEventsVisitId || sv.key) === key) {
          return {
            ...sv,
            [name]:
              type === "number" && value
                ? value
                : type === "checkbox"
                ? value
                  ? 1
                  : 0
                : value,
          };
        }
        return sv;
      });
      return {
        ...prevValues,
        [(country.siteGroupId && `${country.siteGroupId}-${siteId}`) ||
        "study"]: newScheduleVisits,
      };
    });
    if (name !== "load")
      setScheduleOfEventsVisitsChangeLog({
        ...scheduleOfEventsVisitsChangeLog,
        [(country.siteGroupId && `${country.siteGroupId}-${siteId}`) ||
        "study"]: { someValue: "some change" },
      });
  };

  const onVisitsChange = (name, value, key, type) => {
    handleVisitsValueChange(name, value, key, type);
  };

  const renderTableRows = () => {
    return scheduleOfEventsVisits[
      (country.siteGroupId && `${country.siteGroupId}-${siteId}`) || "study"
    ]?.map((sv, index) => {
      return (
        <TableRow
          removeColWidth={removeColWidth}
          key={sv.key || sv.scheduleOfEventsVisitId}
          sv={{ ...sv }}
          index={index}
          removeRow={removeRow}
          onVisitsChange={onVisitsChange}
          value={value}
          fieldType={fieldType}
          columnCount={columnCount}
        />
      );
    });
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (
      destination.draggableId === source.draggableId &&
      destination.index === source.index
    )
      return;

    const newOrderedVisits = scheduleOfEventsVisits["study"];
    const reorderedVisit = newOrderedVisits[source.index];
    newOrderedVisits.splice(source.index, 1);
    newOrderedVisits.splice(destination.index, 0, reorderedVisit);
    setScheduleOfEventsVisits({
      ...scheduleOfEventsVisits,
      study: [...newOrderedVisits],
    });
    setScheduleOfEventsVisitsChangeLog({
      ...scheduleOfEventsVisitsChangeLog,
      [country.siteGroupId || "study"]: { someValue: "some change" },
    });
  };

  return (
    <>
      {!loadingCountrySOE && (
        <Accordion
          scrollIntoView={true}
          width={"100%"}
          summary={"Visits"}
          defaultExpanded={tableVisibility.showVisitTable}
          onClick={() => {
            setTableVisibility("showVisitTable");
          }}
        >
          {tableVisibility.showVisitTable && (
            <Container
              key={country.siteGroupId || "study"}
              onClick={(event) => event.stopPropagation()}
            >
              {!isCountry ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Table>
                    <TableHeader
                      setEditOpen={setEditOpen}
                      visitToUse={currentSOE}
                      value={value}
                      removeColWidth={removeColWidth}
                      fieldType={fieldType}
                      columnCount={columnCount}
                      setOverheadPct={updateVisitOverheadPct}
                    />
                    <Droppable droppableId="visits">
                      {(provided) => (
                        <RowsContainer
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {renderTableRows()}
                          {provided.placeholder}
                        </RowsContainer>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              ) : (
                <Table>
                  <TableHeader
                    setEditOpen={setEditOpen}
                    visitToUse={currentSOE}
                    value={value}
                    removeColWidth={removeColWidth}
                    fieldType={fieldType}
                    columnCount={columnCount}
                    setOverheadPct={updateVisitOverheadPct}
                  />
                  {renderTableRows()}
                </Table>
              )}
              {value === 0 && (
                <ButtonContainer>
                  <Button
                    sx={{ width: "4rem" }}
                    onClick={addNewRow}
                    startIcon={<AddIcon />}
                  >
                    Add Row
                  </Button>
                </ButtonContainer>
              )}
            </Container>
          )}
        </Accordion>
      )}
      {showOverheadRequiredAlert && (
        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            margin: "20px",
            zIndex: 1000,
          }}
        >
          <Alert severity="error">
            Enter a Visit Overhead % on at least 1 visit.
          </Alert>
        </div>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 1rem 0rem;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(p) => (p.noBorder ? "none" : "1px solid var(--Grey-4)")};
  width: 100%;
`;

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
