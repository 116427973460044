import React from "react";
import styled from "styled-components";
import { SectionHeader, Tooltip } from "../../../../app/components";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import SeparateContainer from "../../../../app/components/separate.Container";

const StyledInputBase = withStyles((theme) => ({
  root: {
    borderRadius: "0px",
    border: "1px solid var(--Primary-color)",
    padding: "0px 0px",
    width: "90%",
    "& .MuiInputBase-input": {
      textAlign: "center",
      "-moz-appearance": "textfield", // For Firefox
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        // For Chrome and Safari
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
}))(InputBase);

export default function EditActivationProfiles({
  sitegroup,
  onChange,
  tooltip,
}) {
  const { activationProfiles } = sitegroup;

  return (
    <Layout>
      <SeparateContainer>
        <SideBySide>
          <SectionHeader
            hasStartAdornment={false}
            marginBottom={false}
            endAdornment={
              tooltip && (
                <Tooltip
                  changeColor={true}
                  info={tooltip}
                />
              )
            }
          >
            Site Group Activation Profiles
          </SectionHeader>
        </SideBySide>
        <div style={{ padding: "10px 20px 20px 20px" }}>
          <Table>
            <Row>
              <Cell variant="header">
                <Typography align="right">Percentage</Typography>
              </Cell>
              {activationProfiles.map((item, index) => (
                <Cell key={`Pct-${index}`}>
                  <StyledInputBase
                    type="number"
                    value={item.siteActivationProfilePct}
                    onChange={({ target }) => {
                      onChange(index, "siteActivationProfilePct", target.value);
                    }}
                  />
                </Cell>
              ))}
            </Row>
            <Row>
              <Cell variant="header">
                <Typography align="right">Days</Typography>
              </Cell>
              {activationProfiles.map((item, index) => (
                <Cell key={`Days-${index}`}>
                  <StyledInputBase
                    type="number"
                    value={item.siteActivationProfileDays}
                    onChange={({ target }) =>
                      onChange(index, "siteActivationProfileDays", target.value)
                    }
                  />
                </Cell>
              ))}
            </Row>
          </Table>
        </div>
      </SeparateContainer>
    </Layout>
  );
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 50px;
  border-bottom: 1px solid var(--Grey-4);

  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "var(--Grey-1)" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 0px solid var(--Grey-4);
  &:last-child {
    border: none;
  }
  &:first-child {
    justify-content: flex-end;
    padding-right: 1em;
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  border: 1px solid var(--Grey-4);
  border-radius: 4px;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--Primary-color);
  border-radius: 4px;
`;
