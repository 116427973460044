import { Accordion } from "../../../../app/components";
import { INITIAL_RISK_ASSESSMENT_COLORS } from "../constants";
import React from "react";
import TableHeader from "./table.header";
import TableRow from "./table.row";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

export default function CloneStudyPlan() {
  const {
    initialRiskAssessments,
    initialRiskAssessmentTotal,
    setInitialRiskAssessmentTotal,
  } = useScheduleEvents();

  React.useEffect(() => {
    const total = initialRiskAssessments.reduce(
      (acc, curr) => acc + (+curr.riskAssessmentRiskScore || 0),
      0,
    );
    setInitialRiskAssessmentTotal((prev) => ({
      ...prev,
      total: total.toFixed(2),
    }));
    // eslint-disable-next-line
  }, [initialRiskAssessments]);

  const renderRows = () => {
    return initialRiskAssessments.map((initialRiskAssessment) => {
      return <TableRow data={initialRiskAssessment} />;
    });
  };

  const getColor = (total) => {
    if (total > 26) {
      return INITIAL_RISK_ASSESSMENT_COLORS.RED;
    } else if (total > 18 && total <= 26) {
      return INITIAL_RISK_ASSESSMENT_COLORS.YELLOW;
    } else {
      return INITIAL_RISK_ASSESSMENT_COLORS.GREEN;
    }
  };

  const renderTotalRow = () => {
    return (
      <Row>
        <Cell width="10%"></Cell>
        <Cell width="22%">{initialRiskAssessmentTotal.label}</Cell>
        <Cell
          width="8%"
          style={{ background: getColor(initialRiskAssessmentTotal.total) }}
        >
          {initialRiskAssessmentTotal.total}
        </Cell>
        <Cell width="60%"></Cell>
      </Row>
    );
  };

  return (
    <Accordion
      scrollIntoView={true}
      width={"100%"}
      summary={"Initial Risk Assessment"}
    >
      <Container>
        <TableHeader />
        {renderRows()}
        {renderTotalRow()}
      </Container>
    </Accordion>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
`;

export const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  //   min-height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid var(--Grey-4)")};
  &:last-child {
    border: none;
  }
`;

export const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "var(--Grey-1)" : "white")};
  height: 100%;
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "center"};
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid var(--Grey-4);
  padding: 8px 4px;
  text-align: ${({ textAlign }) => textAlign || "center"};
  border-left: ${(p) => (p.leftBorder ? "1px solid var(--Grey-4)" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid var(--Grey-4)" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--Grey-4)" : "")};
`;
