import React from "react";
import { AccordionTable } from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import { COLUMN_HEADERS, DEFAULT_DATE, COLUMS_MAP } from "./table.utils";
import TableCell from "@material-ui/core/TableCell";

const CountryAndEnrollmentMilestones = () => {
  const { simulationResults } = useStudyPlan();
  const { enrollmentTable = [] } = simulationResults;

  const renderRow = (item) => (
    <>
      <TableCell
        component="th"
        scope="row"
        style={{ borderColor: "var(--Grey-4)" }}
      >
        <strong>{item.SortName}</strong>
      </TableCell>
      {Object.keys(COLUMS_MAP).map((key) => (
        <TableCell
          key={key}
          align="center"
          style={{ borderColor: "var(--Grey-4)" }}
        >
          {item[key] || DEFAULT_DATE}
        </TableCell>
      ))}
    </>
  );

  return (
    <AccordionTable
      summary="Enrollment Milestones"
      columns={COLUMN_HEADERS}
      data={enrollmentTable}
      renderRow={renderRow}
    />
  );
};

export default CountryAndEnrollmentMilestones;
