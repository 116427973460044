import React from "react";
import header_logo from "../../../images/header_logo.png";

const Logo = () => (
  <img
    src={header_logo}
    alt="Remarque"
    height="51px"
  />
);

export default Logo;
