import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../cohort.chart.monthly/chart.css";
import { regionColors, siteGrpColors } from "../colors";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const MonthlyChart = ({ view }) => {
  const { simulationResults } = useStudyPlan();
  const { regionChartMonthly, siteGroupChartMonthly } = simulationResults;
  let data = view === "region" ? regionChartMonthly : siteGroupChartMonthly;
  const colors = view === "region" ? regionColors : siteGrpColors;

  if (!data) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer
        width="100%"
        height={500}
      >
        <ComposedChart
          margin={{ top: 5, right: 15, bottom: 55, left: 15 }}
          data={data.Group ?? []}
          style={{ alignSelf: "center" }}
        >
          <Tooltip wrapperStyle={{ fontFamily: "var(--system-font)" }} />
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="center"
          />
          <XAxis
            dataKey="date"
            fontFamily={"var(--system-font)"}
            scale="band"
            angle={-90}
            dy={35}
            dx={-5}
            interval={0}
          />
          <YAxis
            interval={0}
            label={{
              fontFamily: "var(--system-font)",
              value: "Sites",
              angle: -90,
              position: "insideLeft",
              fill: "#666666",
            }}
            domain={["dataMin", "dataMax + 1"]}
            allowDataOverflow
            fontFamily={"var(--system-font)"}
            yAxisId="1"
            type="number"
          />
          <YAxis
            label={{
              fontFamily: "var(--system-font)",
              value: "Patients",
              angle: 90,
              position: "insideRight",
              fill: "#666666",
            }}
            domain={["dataMin", "dataMax + 10"]}
            allowDataOverflow
            fontFamily={"var(--system-font)"}
            yAxisId="2"
            type="number"
            orientation="right"
          />
          {data.Bars.map((dataKey, index) => (
            <Bar
              yAxisId="1"
              stackId="a"
              dataKey={dataKey}
              key={dataKey}
              barSize={20}
              fill={colors[index]}
            />
          ))}
          <Line
            yAxisId="2"
            type="monotone"
            dataKey="Screened"
            stroke="#F58220"
            strokeWidth={3}
          />
          <Line
            yAxisId="2"
            type="monotone"
            dataKey="Enrolled"
            stroke="#CBDB2A"
            strokeWidth={3}
          />
          <Line
            yAxisId="2"
            type="monotone"
            dataKey="Completed"
            stroke="#b3b3b3"
            strokeWidth={3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlyChart;
