import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckIcon from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import { useHistory, useParams } from "react-router-dom";
import { useStudyPlan } from "../../../app/contexts/study.context";
import { useSnackbar } from "../../../app/contexts/snackbar.context";
import UnsavedChangesModal from "../unsaved.changes.modal";

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      background: "var(--Primary-color)",
    },
  },
  completed: {
    "& $line": {
      background: "var(--Primary-color)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "var(--Grey-2)",
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage: `linear-gradient( 136deg, var(--White) 0%, var(--Primary-color) 50%, var(--Primary-color) 100%)`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage: `linear-gradient( 136deg, var(--White) 0%, var(--Primary-color) 50%, var(--Primary-color) 100%)`,
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();

  const { active, completed } = props;

  const icons = {
    1: completed ? <CheckIcon /> : <Typography variant="h5">1</Typography>,
    2: completed ? <CheckIcon /> : <Typography variant="h5">2</Typography>,
    3: completed ? <CheckIcon /> : <Typography variant="h5">3</Typography>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export default function CustomizedSteppers({ errors }) {
  const history = useHistory();
  const { id, planId } = useParams();
  const [open, setOpen] = useState(false);
  const [routeTo, setRoutTo] = useState("");
  const { studyPlan, saveLoading, unsavedChange } = useStudyPlan();
  const { showSnackbar } = useSnackbar();
  const {
    studyPlanName,
    croProjectStartDateMillis,
    studyPlanDescr,
    finalProtocolStartDateMillis,
    studyPlanId,
    //treatmentDuration,
    //screeningPeriodDuration,
  } = studyPlan;
  const [subjects_to_screen] = studyPlan?.parameters;

  const handleDisabled = () =>
    !studyPlanName ||
    !croProjectStartDateMillis ||
    !studyPlanDescr ||
    !finalProtocolStartDateMillis ||
    !subjects_to_screen.lowerBound > 0 ||
    !studyPlanId;

  const handleActiveStep = () => {
    if (history.location.pathname.includes("review")) {
      return 2;
    } else if (
      history.location.pathname.includes("countries-and-site-groups")
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  const steps = [
    {
      label: "Overall Parameters",
      to: `/ctpo/study-plans/${id}/${planId}`,
      step: 1,
    },
    {
      label: "Countries & Site Groups",
      to: `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups`,
      step: 2,
    },
    {
      label: "Review Study Plan",
      to: `/ctpo/study-plans/${id}/${planId}/review`,
      step: 3,
    },
  ];

  const handleClick = (to, step) => {
    studyPlan["ActualStep"] = step;
    if (handleDisabled()) {
      return;
    }
    if (unsavedChange) {
      if (
        Object.keys(errors || {}).length !== 0 &&
        (["ALL"].includes(errors.BUTTON_TYPE) || to?.includes("review"))
      ) {
        showSnackbar(
          <ul>
            {Object.keys(errors || {}).map((key) => {
              if (key === "ENROLLMENT_DURATION" && !to?.includes("review"))
                return "";
              if (key !== "BUTTON_TYPE") return <li>{errors[key]}</li>;
              return "";
            })}
          </ul>,
          "error",
        );
        history.push(to);
        return;
      }
      setOpen(true);
    } else {
      history.push(to);
    }
  };

  return (
    <Stepper
      alternativeLabel
      activeStep={handleActiveStep()}
      connector={<ColorlibConnector />}
    >
      {steps.map(({ label, to, step }) => (
        <Step
          key={label}
          onClick={() => {
            handleClick(to, step);
            setRoutTo(to);
          }}
          disabled={saveLoading}
        >
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <strong>{label}</strong>
          </StepLabel>
        </Step>
      ))}

      <UnsavedChangesModal
        open={open}
        onToggle={(prev) => setOpen(!prev)}
        to={routeTo}
      />
    </Stepper>
  );
}
