import React from "react";
import PropTypes from "prop-types";
import Accordion from "../accordion";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/styles";
import "./styles.css";

const AccordionTable = (props) => {
  const classes_rows = useRowStyles();
  const rotate_cells = useRotationStyles();
  const classes_cells = useCellStyles();
  const { columns = {}, data = [], renderRow, rotate = false } = props;
  return (
    <Accordion {...props}>
      <TableContainer>
        <Table
          aria-label="customized table"
          className="table-container"
        >
          <TableHead>
            <TableRow
              variant="head"
              classes={classes_rows}
            >
              <TableCell></TableCell>
              {Object.keys(columns).map((col) => (
                <TableCell
                  key={col}
                  align="center"
                  classes={rotate ? rotate_cells : classes_cells}
                >
                  <p
                    style={{
                      transform: rotate ? "rotate(-180deg)" : "rotate(0)",
                    }}
                  >
                    {columns[col]}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>{renderRow(row)}</TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Accordion>
  );
};

const useRowStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
  },
}));
const useRotationStyles = makeStyles((theme) => ({
  root: {
    writingMode: "vertical-rl",
    textOrientation: "mixed",
    fontWeight: 600,
  },
}));

const useCellStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 600,
  },
}));

export default AccordionTable;

AccordionTable.propTypes = {
  columns: PropTypes.object,
  summary: PropTypes.string,
  defaultExpanded: PropTypes.bool,
};

AccordionTable.defaultProps = {
  defaultExpanded: false,
};
