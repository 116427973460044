import React, { Fragment } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStudyPlan } from "../../app/contexts/study.context";
import moment from "moment";
import ViewSiteListModal from "./ViewSiteListModal";
import PrimaryButton from "../../app/components/buttons/primary";

const Controls = ({ search = "", onSearch, clearSearch }) => {
  const [remodelViewOpen, setRemodelViewOpen] = React.useState(false);
  const { studyActualsData, loadStudyActuals, lActualsLoading } =
    useStudyPlan();
  const { actualsData = [] } = studyActualsData;
  const history = useHistory();
  const { id } = useParams();

  const handleRemodel = () => {
    history.push(`/ctpo/study-actuals/${id}/remodel`);
  };

  const checkRemodelRun = () => {
    if (actualsData.length > 0 && moment(actualsData[0].toolTips).isValid()) {
      return (
        (moment().diff(moment(actualsData[0].toolTips)) +
          new Date().getTimezoneOffset() * 60 * 1000) /
          3600000 >=
        24
      );
    }
    return true;
  };

  return (
    <Container>
      {actualsData.length > 0 ? (
        <Fragment>
          {lActualsLoading ? (
            <CircularProgress
              color="white"
              size="2em"
            />
          ) : (
            <Fragment>
              <PrimaryButton
                disabled={checkRemodelRun()}
                onClick={handleRemodel}
              >
                REMODEL
              </PrimaryButton>
              <PrimaryButton
                disabled={checkRemodelRun()}
                onClick={() => setRemodelViewOpen(true)}
              >
                VIEW SITE LIST
              </PrimaryButton>
              <PrimaryButton onClick={loadStudyActuals}>
                IMPORT ACTUALS
              </PrimaryButton>

              <ViewSiteListModal
                remodelViewOpen={remodelViewOpen}
                setRemodelViewOpen={setRemodelViewOpen}
              />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <div></div>
      )}
    </Container>
  );
};
export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 1em;
  align-content: center;
`;
