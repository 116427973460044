import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { SectionHeader, Tooltip } from "../../../../app/components";
import SeparateContainer from "../../../../app/components/separate.Container";

export default function SFDORates({ errors, touched, values, setValues }) {
  const rows = [
    {
      header: "",
      columns: ["Screen Fail Rate", "Drop Out Rate"],
    },
    {
      header: "Planned",
      columns: [values?.screenFailRatePlanned, values?.dropOutRatePlanned],
    },
    {
      header: "Actual",
      columns: [values?.screenFailRateActual, values?.dropOutRateActual],
    },
    {
      header: "Impact",
      columns: [values?.screenFailRateImpact, values?.dropOutRateImpact],
    },
  ];
  return (
    <Container>
      <SeparateContainer>
        <SectionHeader
          hasStartAdornment={false}
          endAdornment={
            <Tooltip
              changeColor={true}
              info="Using the information in the first table, decide how many patients you want to include in the remodel and complete the fields in the second table. Note: You will have the option to include a specific number of patients in a particular country before completing the remodel (if needed)."
            />
          }
        >
          SF/DO Rates & Patients
        </SectionHeader>
        <TableContainer>
          <Table>
            {rows.map((row, rowIndex) => (
              <Row key={rowIndex}>
                {rowIndex === 0 ? (
                  <Cell variant="header"></Cell>
                ) : (
                  <Cell variant="header">
                    <Typography align="center">
                      <strong>{row.header}</strong>
                    </Typography>
                  </Cell>
                )}
                {row.columns.map((column, colIndex) => (
                  <Cell key={colIndex}>
                    <Typography align="center">{column}</Typography>
                  </Cell>
                ))}
              </Row>
            ))}
          </Table>
        </TableContainer>
      </SeparateContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 10px 20px 20px 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 19em;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "var(--Grey-1)" : "white")};
  height: 8em;
  align-items: center;
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid var(--Grey-4);
  padding: 0.5em;
  padding-left: 3em;

  &:last-child {
    border-bottom: none;
  }
`;

const Table = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid var(--Grey-4);
  border-radius: 4px;
  border-bottom: 1px solid var(--Grey-4);
`;
