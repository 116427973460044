import React, { useEffect, useState } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useUser } from "../../../contexts/user.context";
import { withStyles } from "@material-ui/core/styles";

let gNavigation = [
  {
    label: "Home",
    to: `/ctpo/study-plans/`,
  },
  {
    label: "Study Plans",
    to: `/ctpo/study-plans/`,
  },
  {
    label: "Study Actuals",
    to: `/ctpo/study-actuals/`,
  },
  // { label: "Admin Data", to: `/ctpo/admin-data/` },
];

const StyledTab = withStyles((theme) => ({
  root: {
    color: "var(--White)",
    opacity: 1,
    "&:hover": {
      color: "var(--Grey-2)",
      opacity: 0.8,
    },
  },
}))((props) => (
  <Tab
    disableRipple
    {...props}
  />
));

const Navigation = () => {
  const history = useHistory();
  const [current, setCurrent] = useState(() => defaultSelected(history));
  const { authState } = useOktaAuth();
  const { idToken } = authState;
  const { urlStudyId, updateNavigation } = useUser();
  const [navigation, setNavigation] = useState([]);

  useEffect(() => {
    if (!urlStudyId) {
      if (!"/ctpo/study-plans/".includes(history.location.pathname)) {
        gNavigation = [
          {
            label: "Home",
            to: `/ctpo/study-plans/`,
          },
        ];
        setNavigation(gNavigation);
      } else {
        gNavigation = [];
        setNavigation(gNavigation);
      }
    } else {
      gNavigation = [
        {
          label: "Home",
          to: `/ctpo/study-plans/`,
        },
        {
          label: "Study Plans",
          to: urlStudyId
            ? `/ctpo/study-plans/${urlStudyId}`
            : `/ctpo/study-plans/`,
        },
        {
          label: "Study Actuals",
          to: urlStudyId
            ? `/ctpo/study-actuals/${urlStudyId}`
            : `/ctpo/study-actuals/`,
        },
      ];
      setNavigation(gNavigation);
      if (history.location.pathname.includes("study-plans")) {
        setCurrent(1);
      }
      if (history.location.pathname.includes("study-actuals")) {
        setCurrent(2);
      }
    }
    // eslint-disable-next-line
  }, [updateNavigation, urlStudyId]);

  if (!idToken) return null;

  const handleChange = (ev, index) => {
    const location = navigation[index].to;
    setCurrent(index);
    history.push(location);
  };

  return (
    <Container>
      <Tabs
        value={current}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: "var(--White)",
          },
        }}
      >
        {navigation.map((nav) => (
          <StyledTab
            key={nav.to}
            label={nav.label}
          />
        ))}
      </Tabs>
    </Container>
  );
};

export default Navigation;

const Container = styled.div`
  margin: 0 1em;
`;

const defaultSelected = (history) => {
  const { pathname } = history.location;
  pathname.lastIndexOf("/");
  return gNavigation.findIndex((loc) => pathname.includes(loc.to));
};
