import React from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import ScenarioA from "./scenario.a";
import ScenarioB from "./scenario.b";

const CountryInputs = (props) => {
  const { studyPlan, STUDY_PLAN_TYPES, canEditPlan } = useStudyPlan();

  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;
  let scenarioRender;
  if (studyPlanType === SCENARIO_A) {
    scenarioRender = <ScenarioA {...props} />;
  }

  if (studyPlanType === SCENARIO_B) {
    scenarioRender = <ScenarioB {...props} />;
  }

  return (
    <Container>
      <div style={{ padding: "20px" }}>
        <div>{scenarioRender}</div>
      </div>
    </Container>
  );
};

export default CountryInputs;

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
`;
