import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
//import ClearIcon from "@material-ui/icons/Clear";
//import IconButton from "@material-ui/core/IconButton";
import NewStudyPlanModal from "./new.study.plan.modal";
//import SearchIcon from "@material-ui/icons/Search";
//import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { nanoid } from "nanoid";
import styled from "styled-components";
import { useStudyPlan } from "../../../app/contexts/study.context";
import PrimaryButton from "../../../app/components/buttons/primary";

const Controls = ({ search = "", onSearch, clearSearch }) => {
  const classes = useStyles();
  //const [open, setOpen] = useState(false);
  const [openNewPlanModal, setOpenNewPlanModal] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { createNewCohortPlan, createNewPlan } = useStudyPlan();

  const redirect = (planId) => {
    history.replace(`/ctpo/study-plans/${id}/${planId}`);
  };

  // const handleSearch = (e) => {
  //   onSearch(e.target.value);
  // };

  // const handleClear = () => {
  //   setOpen(false);
  //   clearSearch();
  // };

  const handleClick = async (
    scenario,
    selectedStudyPlans,
    values,
    sequentialCohort,
  ) => {
    createNewPlan(scenario, sequentialCohort ? "true" : "false");
    if (scenario === "scenario-c") {
      createNewCohortPlan(selectedStudyPlans, id, values, redirect);
    } else {
      const new_plan_temp_id = "new-plan_" + nanoid();
      history.push(
        `/ctpo/study-plans/${id}/${new_plan_temp_id}?s=${scenario}&seq=${
          sequentialCohort || false
        }`,
      );
    }
  };

  return (
    <Container>
      {/* <SearchContainer>
        <IconButton onClick={() => setOpen((prev) => !prev)}>
          <SearchIcon className={classes.search} />
        </IconButton>
        {open && (
          <TextField
            value={search}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClear}>
                  <ClearIcon className={classes.clear} />
                </IconButton>
              ),
            }}
          />
        )}
      </SearchContainer> */}

      <PrimaryButton onClick={() => setOpenNewPlanModal(true)}>
        Add New Plan
      </PrimaryButton>

      <NewStudyPlanModal
        onContinue={handleClick}
        open={openNewPlanModal}
        onClose={() => setOpenNewPlanModal(false)}
      />
    </Container>
  );
};
export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 1em;
  align-content: center;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  search: {
    color: "#797979",
  },
  clear: {
    color: "#b2b2b2",
  },
}));
