import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useScheduleEvents } from "../../app/contexts/schedule.events.context";
import { useStudyPlan } from "../../app/contexts/study.context";
import Controls from "./controls";
import TableHead from "./table.head";
import TableRow from "./table.row";
import { debounce } from "lodash";
import TableHeadFilter from "./table.head/filter";
import moment from "moment";

const ROWS_PER_PAGE_KEY = "ctpo_study_plan_table_rows_per_page";
const defaultRowsPerPage = () =>
  localStorage.getItem(ROWS_PER_PAGE_KEY)?.toString() || 5;

/*
  This component is responsible for rendering the table of schedule of events
  It uses the schedule events context to handle the schedule of events.
  It uses the study context to handle the study plan.
*/
export default function ScheduleEventsTable() {
  const [filter, setFilter] = useState("");

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [filterActive, setFilterActive] = useState(false);

  const classes = useStyles();
  const { scheduleOfEvents, loadingSOEStudy, savingSOEStudy } =
    useScheduleEvents();
  const { study } = useStudyPlan();
  const { studyPlans } = study;
  const [filters, setFilters] = useState({});
  const [filteredStudyPlans, setFilteredStudyPlans] = useState(studyPlans);
  const [shouldRenderPagination, setShouldRenderPagination] = useState(false);

  /**
   * Searching
   */

  const debounceFilter = useMemo(
    () =>
      debounce((lFilters) => {
        const filterKeys = Object.keys(lFilters);
        if (!filterKeys.length) return setFilteredStudyPlans(scheduleOfEvents);
        // for each filter, filter study plans using the id and value and set the filtered study plans

        const filtered = scheduleOfEvents.filter((event) => {
          return filterKeys.every((filterId) => {
            const { value, type, start, end } = lFilters[filterId];
            if (type === "date") {
              if (!start || !end) return true;
              const startDate = moment(start).valueOf();
              const endDate = moment(end).valueOf();
              if (!event[filterId]) return false;
              const projectDate = moment(event[filterId]).valueOf();
              let startD = new Date(startDate);
              let endD = new Date(endDate);
              let projectD = new Date(projectDate);

              startD = new Date(
                startD.getFullYear(),
                startD.getMonth(),
                startD.getDate(),
              );
              endD = new Date(
                endD.getFullYear(),
                endD.getMonth(),
                endD.getDate(),
              );
              projectD = new Date(
                projectD.getFullYear(),
                projectD.getMonth(),
                projectD.getDate(),
              );

              if (projectD >= startD && projectD <= endD) return true;
              else return false;
            }
            if (type === "select") {
              if (!event[filterId]) return false;
            }

            const normalizedValue = value.toLowerCase().trim();

            if (event[filterId]) {
              if (filterId === "studyPlanId") {
                return studyPlans.some(
                  (x) =>
                    x.studyPlanName.toLowerCase().includes(normalizedValue) &&
                    x.studyPlanId === event[filterId],
                );
              } else {
                return event[filterId].toLowerCase().includes(normalizedValue);
              }
            } else {
              return normalizedValue === "";
            }
          });
        });
        setFilteredStudyPlans(filtered);
        setPage(0);
      }, 500),
    [scheduleOfEvents, studyPlans],
  );

  useEffect(() => {
    debounceFilter(filters);
  }, [debounceFilter, filters]);

  useEffect(() => {
    const hasScheduleOfEventsId = filteredStudyPlans.some(
      (row) => row.scheduleOfEventsId,
    );
    setShouldRenderPagination(hasScheduleOfEventsId);
  }, [filteredStudyPlans]);

  /**
   *  Sorting Fuctions
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  /**
   * Pagination
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const rows = parseInt(value);

    setRowsPerPage(rows);
    setPage(0);

    localStorage.setItem(ROWS_PER_PAGE_KEY, rows);
  };

  if (loadingSOEStudy) return <LinearProgress />;

  return (
    <Fragment>
      <Controls
        search={filter}
        onSearch={(value) => setFilter(value)}
        clearSearch={() => setFilter("")}
        onNewPlan={(noop) => noop}
      />
      <Paper style={{ border: "1px solid var(--Grey-4)" }}>
        <TableContainer classes={classes}>
          <Table
            size="medium"
            aria-label="schedule of events table"
          >
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              filterActive={filterActive}
              setFilterActive={setFilterActive}
            />
            {filterActive && (
              <TableHeadFilter
                filters={filters}
                setFilters={setFilters}
                filterActive={filterActive}
                setFilterActive={setFilterActive}
              />
            )}
            <TableBody>
              {stableSort(filteredStudyPlans, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    soe={row}
                    key={row.scheduleOfEventsId}
                    {...row}
                    scheduleOfEvents={scheduleOfEvents || []}
                    studyPlans={studyPlans || []}
                    savingSOEStudy={savingSOEStudy}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {shouldRenderPagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            rowsPerPage={rowsPerPage}
            page={page}
            count={filteredStudyPlans.length || 0}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
  },
}));
