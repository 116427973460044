import { Checkbox, CircularProgress } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Modal } from "../../../../app/components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { ScenarioC } from "./ScenarioC";
import PrimaryButton from "../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../app/components/buttons/secondary";

const NewStudyPlanModal = (props) => {
  const classes = useStyles();
  const [scenario, setScenario] = useState();
  const { study, saveLoading } = useStudyPlan();
  const [selectedStudyPlans, setSelectedStudyPlans] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [sequentialCohort, setSequentialCohort] = useState(false);
  const [cohortPlanParameters, setCohortPlanParameters] = useState({
    studyPlanName: "",
    studyPlanDescr: "",
  });
  const [errors, setErrors] = useState({
    studyPlanName: "",
    studyPlanDescr: "",
  });

  const transferListPlans = useMemo(
    () =>
      study.studyPlans?.filter((sp) =>
        ["Duration", "Rates", "Cohort (D)"].includes(sp.studyPlanTypeDetail),
      ),
    [study],
  );

  useEffect(() => {
    if (
      cohortPlanParameters["studyPlanDescr"] &&
      cohortPlanParameters["studyPlanName"] &&
      !errors["studyPlanDescr"] &&
      !errors["studyPlanName"] &&
      selectedStudyPlans.length >= 2
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [cohortPlanParameters, errors, selectedStudyPlans]);

  const handleChange = (key, value) => {
    setCohortPlanParameters({ ...cohortPlanParameters, [key]: value });
    if (!value) {
      setErrors({ ...errors, [key]: "This field is required" });
    } else {
      setErrors({ ...errors, [key]: "" });
    }
    if (key === "studyPlanName") {
      study.studyPlans.forEach((sp) => {
        if (
          sp?.studyPlanName.toLowerCase().trim() === value.toLowerCase().trim()
        ) {
          setErrors({
            ...errors,
            [key]: "Study Plan Name must be unique",
          });
        }
      });
    }
  };

  const resetToDefaultState = () => {
    setScenario(undefined);
    setSelectedStudyPlans([]);
    setFormValid(false);
    setSequentialCohort(false);
    setCohortPlanParameters({
      studyPlanName: "",
      studyPlanDescr: "",
    });
    setErrors({
      studyPlanName: "",
      studyPlanDescr: "",
    });
  };

  return (
    <Modal
      size={700}
      {...props}
      aria-labelledby="New Study Plan"
      aria-describedby="Create a new study plan"
      title="New Study Plan"
      onClose={() => {
        resetToDefaultState();
        props.onClose();
      }}
    >
      <Container>
        <Typography>
          What type of study plan would you like to create?
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            style={{ alignItems: "flex-start" }}
            row
            aria-label="scenario"
            name="scenarios"
            value={scenario}
            onChange={(e) => setScenario(e.target.value)}
          >
            <FormControlLabel
              value="scenario-a"
              control={<Radio />}
              label="Enrollment Rates"
            />
            <AlignVertical>
              <FormControlLabel
                value="scenario-b"
                control={<Radio />}
                label="Overall Enrollment Duration"
              />
              {scenario === "scenario-b" && (
                <FormControlLabel
                  onChange={(e) => setSequentialCohort(e.target.checked)}
                  checked={sequentialCohort}
                  control={<Checkbox />}
                  label="Sequential Cohorts"
                />
              )}
            </AlignVertical>
            <FormControlLabel
              value="scenario-c"
              control={<Radio />}
              label="Combination"
            />
          </RadioGroup>
        </FormControl>
        {scenario === "scenario-c" && (
          <ScenarioC
            transferListPlans={transferListPlans}
            setSelectedStudyPlans={setSelectedStudyPlans}
            cohortPlanParameters={cohortPlanParameters}
            classes={classes}
            errors={errors}
            handleChange={handleChange}
          />
        )}
        <ButtonContainer>
          <SecondaryButton
            onClick={() => {
              resetToDefaultState();
              props.onClose();
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            disabled={
              saveLoading ||
              !scenario ||
              (scenario === "scenario-c" && !formValid)
            }
            onClick={() =>
              props.onContinue(
                scenario,
                selectedStudyPlans,
                cohortPlanParameters,
                sequentialCohort,
              )
            }
          >
            {saveLoading && <CircularProgress size="1em" />}
            {!saveLoading && "Continue"}
          </PrimaryButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default NewStudyPlanModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 0 1em;
  max-height: 75vh;
  overflow-y: auto;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const useStyles = makeStyles({
  asterisk: {
    color: "red",
  },
});

const AlignVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
