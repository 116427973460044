import styled from "styled-components";

const Canvas = styled.div`
  height: calc(100vh - 61px);
  width: 100%;
  top: 61px;
  left: 0;
  position: absolute;

  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
  }
`;

export default Canvas;
