import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

const TertiaryButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.tertiaryButton}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default TertiaryButton;

const useStyles = makeStyles((theme) => ({
  tertiaryButton: {
    color: "var(--Primary-color)",
  },
}));
