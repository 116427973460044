import React, { useState } from "react";

import { Checkbox } from "@material-ui/core";
import MuiTooltip from "@material-ui/core/Tooltip";
import MultiplierInput from "./multiplier.input";
import styled from "styled-components";

/*
  This component is responsible for rendering the table row of the schedule of events table.
  The parent component must pass a callback function to handle the selection of the table row.
  The parent component must pass the type of the table row.
  The parent component must pass the total title of the table row.
*/

///este es
export default function TableRow({
  sortedAssessmentRowValue,
  type,
  totalTitle,
  totalValues = [],
  assessment,
  activitiesPercentile,
  visitsCount,
  visitsAndAssessmentsMapping,
  handleChange,
  fieldType,
}) {
  const [checkAllChecked, setCheckAllChecked] = React.useState(
    Math.random().toString(),
  );

  const [allChecked, setAllChecked] = React.useState(
    visitsAndAssessmentsMapping[
      assessment?.key || assessment?.scheduleOfEventsAssessmentId
    ]?.reduce((acc, curr) => acc && curr.value, true),
  );

  const [visitCheckBoxStates, setVisitCheckBoxStates] = useState(() => {
    if (!sortedAssessmentRowValue) {
      return {};
    }

    const initialState = sortedAssessmentRowValue.reduce((acc, visit) => {
      const key = visit.scheduleOfEventsVisitId;
      acc[key] = visit.value || false;
      return acc;
    }, {});

    return initialState;
  });

  const renderTotalCells = () => {
    const cells = new Array(visitsCount).fill(0);
    return cells.map((_, index) => {
      return (
        <Cell
          style={{ textAlign: "center", fontWeight: "bold" }}
          key={index}
          width={`${(1 / visitsCount) * (100 - activitiesPercentile)}%`}
        >
          {totalValues[index] ?? "-"}
        </Cell>
      );
    });
  };

  const shortContent = (content) => {
    if (content.length > 20) {
      return `${content.substring(0, 20)}...`;
    }
    return content;
  };

  const debounce = (func, wait, immediate) => {
    let timeoutId;
    return function () {
      const context = this,
        args = arguments;

      const later = function () {
        timeoutId = null;
        if (!immediate) func.apply(context, args);
      };

      const callNow = immediate && !timeoutId;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  };

  const handleChangeCheckbox = (key, isChecked) => {
    setVisitCheckBoxStates((prev) => {
      return {
        ...prev,
        [key]: isChecked,
      };
    });

    if (isChecked === false) {
      setAllChecked(allChecked[key] === false);
    }
  };

  const handleAllCheck = (isChecked) => {
    const updatedCheckBoxStates = {};
    setAllChecked(isChecked);
    sortedAssessmentRowValue.forEach((visit) => {
      const key = visit.scheduleOfEventsVisitId;
      updatedCheckBoxStates[key] = isChecked;
      setVisitCheckBoxStates((updatedCheckBoxStates[key] = isChecked));
    });

    setVisitCheckBoxStates(updatedCheckBoxStates);
  };

  const debounceOnHandleChange = debounce(handleChange, 500);

  return (
    <Row style={type === "total" ? { minHeight: "50px" } : {}}>
      {type === "total" ? (
        <>
          <Cell
            style={{
              justifyContent: "right",
              padding: "5px 1em",
              minWidth: "400px",
              position: "sticky",
              left: 0,
              zIndex: 10,
              fontWeight: "bold",
            }}
            width={`${activitiesPercentile}%`}
          >
            {totalTitle}
          </Cell>
          {renderTotalCells()}
        </>
      ) : (
        <>
          <Cell
            style={{
              justifyContent: "left",
              padding: "5px 1em",
              minWidth: "400px",
              position: "sticky",
              left: 0,
              zIndex: 10,
            }}
            width={`${activitiesPercentile}%`}
          >
            <Checkbox
              style={{ padding: "0px" }}
              key={allChecked ? "checked" : "unchecked"}
              checked={allChecked}
              onChange={(e) => {
                handleChange(
                  assessment?.key || assessment?.scheduleOfEventsAssessmentId,
                  "all",
                  e.target.checked,
                );
                handleAllCheck(e.target.checked);
                setTimeout(() => {
                  setCheckAllChecked(Math.random().toString());
                }, 1000);
              }}
              name="detailsMonthly"
            />
            <TypeText>{assessment?.assessmentType || "No-Type"}</TypeText>
            <p style={{ textAlign: "left" }}>
              <MuiTooltip
                title={
                  <p style={{ fontSize: "1rem" }}>
                    {assessment?.assessmentName}
                  </p>
                }
              >
                <span>{shortContent(assessment?.assessmentName)}</span>
              </MuiTooltip>
            </p>
          </Cell>
          {sortedAssessmentRowValue?.map((visit, index) => {
            const key = visit.scheduleOfEventsVisitId;
            return (
              <Cell
                key={visit.key || visit.scheduleOfEventsVisitId}
                width={`${(1 / visitsCount) * (100 - activitiesPercentile)}%`}
              >
                {/* aqui */}
                {visitCheckBoxStates[key]}
                <Checkbox
                  style={{ padding: "0px" }}
                  checked={visitCheckBoxStates[key]}
                  onChange={(e) => {
                    handleChangeCheckbox(key, e.target.checked);

                    debounceOnHandleChange(
                      assessment?.key ||
                        assessment?.scheduleOfEventsAssessmentId,
                      visit.key || visit.scheduleOfEventsVisitId,
                      e.target.checked,
                    );
                  }}
                  name="detailsMonthly"
                />
                <MultiplierInput
                  cellChecked={visitCheckBoxStates[key]}
                  onMultiplierChange={(value) => {
                    handleChange(
                      assessment?.key ||
                        assessment?.scheduleOfEventsAssessmentId,
                      visit.key || visit.scheduleOfEventsVisitId,
                      value,
                      "multiplier",
                    );
                  }}
                  onUniqueChange={(value) => {
                    handleChange(
                      assessment?.key ||
                        assessment?.scheduleOfEventsAssessmentId,
                      visit.key || visit.scheduleOfEventsVisitId,
                      value,
                      "uniqueNonUniqueForm",
                    );
                  }}
                  multiplier={visit.multiplier}
                  uniqueNonUnique={visit.uniqueNonUniqueForm}
                  fieldType={fieldType}
                />
              </Cell>
            );
          })}
        </>
      )}
    </Row>
  );
}

const TypeText = styled.p`
  min-width: 130px;
  width: 25%;
  background-color: #f3f4f4;
  height: fit-content;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px;
  border-radius: 1em;
  font-size: 0.9em;
`;

const Row = styled.div`
  position: relative;
  width: fit-content;
  min-width: 100%;
  display: flex;
  // min-height: 50px;
  height: fit-content;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid var(--Grey-4)")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  min-width: 220px;
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid var(--Grey-4);
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid var(--Grey-4)" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid var(--Grey-4)" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--Grey-4)" : "")};
`;
