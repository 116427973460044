import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { SectionHeader, Tooltip } from "../../../../app/components";
import SpecialRadioButton from "../../../../app/components/special.radiobutton";
import { headerData, NUMBER_SITES_INCLUDE_PROJECTIONS_INFO } from "./constants";
import SeparateContainer from "../../../../app/components/separate.Container";

export default function CountrySites({ errors, touched, values, setValues }) {
  const renderCountrySites = () => {
    return values?.siteGroups?.map((siteGroup, index) => {
      return (
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="scenario"
            name="scenarios"
            value={siteGroup.sitesSelection}
            onChange={(e) => {
              if (!e.target.value) return;
              let siteGroupLocal = siteGroup;
              siteGroupLocal["sitesSelection"] = parseFloat(e.target.value);
              let siteGroups = values.siteGroups.map((sg, i) => {
                if (i === index) return siteGroupLocal;
                return sg;
              });
              setValues({ ...values, siteGroups });
            }}
          >
            <Row>
              <Cell flex={1}>
                <Typography style={{ textAlign: "left", width: "100%" }}>
                  {siteGroup.siteGroupName}
                </Typography>
              </Cell>
              <Cell flex={2}>
                {!(siteGroup?.sitesPlanned === null) && (
                  <FormControlLabel
                    style={{ minWidth: "5rem" }}
                    value={1}
                    control={<Radio color="primary" />}
                    label={siteGroup?.sitesPlanned}
                  />
                )}
              </Cell>
              <Cell flex={2}>
                {!(siteGroup?.sitesActual === null) && (
                  <FormControlLabel
                    style={{ minWidth: "5rem" }}
                    value={2}
                    control={<Radio color="primary" />}
                    label={
                      <>
                        {siteGroup?.sitesActual}
                        {siteGroup?.sitesActualClosed !== null && (
                          <> (+{siteGroup?.sitesActualClosed} closed sites)</>
                        )}
                      </>
                    }
                  />
                )}
              </Cell>
              <Cell flex={2}>
                <SpecialRadioButton
                  type="number"
                  currentRadioValue={3}
                  onChange={(value) => {
                    let siteGroupLocal = siteGroup;
                    siteGroupLocal["sitesOther"] = parseFloat(value);
                    let siteGroups = values.siteGroups.map((sg, i) => {
                      if (i === index) return siteGroupLocal;
                      return sg;
                    });
                    setValues({ ...values, siteGroups });
                  }}
                  value={siteGroup?.sitesOther}
                  error={
                    errors?.siteGroups?.length >= index &&
                    !!errors?.siteGroups[index]?.sitesOther
                  }
                />
              </Cell>
              <Cell
                flex={2}
                alignItems="center"
                justifyContent="center"
              >
                <FormControlLabel
                  value={4}
                  control={
                    <Radio
                      color="primary"
                      disabled={
                        siteGroup?.sitesActual > 0 ||
                        siteGroup?.sitesActualClosed > 0
                      }
                    />
                  }
                  label=""
                />
              </Cell>
            </Row>
          </RadioGroup>
        </FormControl>
      );
    });
  };

  return (
    <Container>
      <SeparateContainer>
        <SectionHeader
          hasStartAdornment={false}
          endAdornment={
            <Tooltip
              changeColor={true}
              info={
                <>
                  {NUMBER_SITES_INCLUDE_PROJECTIONS_INFO.Paragraph1}
                  <br />
                  <br />
                  {NUMBER_SITES_INCLUDE_PROJECTIONS_INFO.Paragraph2}
                  <br />
                  <br />
                  {NUMBER_SITES_INCLUDE_PROJECTIONS_INFO.Paragraph3}
                </>
              }
            />
          }
        >
          Country - Number of Sites to Include in Projections
        </SectionHeader>
        <TableContainer>
          <Table>
            <Row>
              <Cell
                variant="header"
                flex={1}
              ></Cell>
              {headerData.map(({ text, flex }) => (
                <Cell
                  key={text}
                  variant="header"
                  flex={flex}
                >
                  <Typography align="center">
                    <strong>{text}</strong>
                  </Typography>
                </Cell>
              ))}
            </Row>
            {renderCountrySites()}
          </Table>
        </TableContainer>
        {errors.countrySites && (
          <ErrorMessageContainer className="MuiFormHelperText-root">
            {errors.countrySites}
          </ErrorMessageContainer>
        )}
      </SeparateContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 10px 20px 20px 20px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-between;
  flex-grow: 1;
  border-bottom: 1px solid var(--Grey-4);
  height: 6em;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "var(--Grey-1)" : "white")};
  flex: ${(p) => p.flex || "1"};
  align-items: ${(p) => p.alignItems || "center"};
  justify-content: ${(p) => p.justifyContent || "center"};
  display: flex;
  padding: 1em;
  padding-left: 3em;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid var(--Grey-4);
  border-bottom: none;
  border-radius: 4px;
`;

const ErrorMessageContainer = styled.div`
  color: #f44336 !important;
  margin: 3px 14px !important;
`;
