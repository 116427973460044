import React from "react";
import styled from "styled-components";
import MuiTypography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const SectionHeader = ({
  children,
  endAdornment,
  hasStartAdornment = true,
  marginBottom = true,
  isBold = true,
  useBackground = true,
  paddingBottom = true,
}) => {
  const classes = useStyles({ useBackground: useBackground });

  return (
    <Container
      marginBottom={marginBottom}
      useBackground={useBackground}
      isFlex={hasStartAdornment || endAdornment}
      paddingBottom={paddingBottom}
    >
      <MuiTypography className={isBold ? classes.root : classes.rootLight}>
        {children}
      </MuiTypography>
      {endAdornment && (
        <div
          style={{ marginLeft: "0.5em", display: "flex", alignItems: "center" }}
        >
          {endAdornment}
        </div>
      )}
    </Container>
  );
};

export default SectionHeader;

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    fontSize: "1.2rem",
    fontWeight: "600",
    color: props.useBackground ? "var(--White)" : "var(--Primary-color)",
  }),
  rootLight: (props) => ({
    fontSize: "1.2rem",
    fontWeight: "600",
    color: props.useBackground ? "var(--White)" : "var(--Primary-color)",
  }),
}));

const Container = styled.div`
  display: ${({ isFlex }) => (isFlex ? "flex" : "inherit")};
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "1em" : "0")};
  background-color: ${({ useBackground }) =>
    useBackground ? "var(--Primary-color)" : "transparent"};
  padding-inline: 0.5em;
  border-radius: 4px;
  padding-top: 0.5em;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? "0.5em" : "0")};
`;
