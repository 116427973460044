import React from "react";
import styled from "styled-components";

const SeparateContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default SeparateContainer;

const Container = styled.div`
  border-top: 0.1px solid rgba(224, 224, 224, 0.25);
  border-left: 0.1px solid rgba(224, 224, 224, 0.5);
  border-right: 0.1px solid rgba(224, 224, 224, 0.5);
  border-bottom: 2px solid rgba(224, 224, 224, 1);
  border-radius: 10px;
  overflow-x: auto;
  width: 100%;
`;
