import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import DeleteCountyModal from "./delete.country.modal";
import EditCountryModal from "./edit.country.modal";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useStudyPlan } from "../../contexts/study.context";
import SectionHeader from "../section.header";

const CountryHeader = ({
  title,
  countryId,
  siteGroup,
  variant = "primary",
  hasDelete = true,
  hasEdit = true,
  studyPlanType,
  currentEffort,
  currentTimeframe,
  tab,
}) => {
  const history = useHistory();
  const { id, planId } = useParams();
  const { canEditPlan } = useStudyPlan();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const classes = useStyles();
  return (
    <Container variant={variant}>
      <HeaderContainer>
        <SectionHeader paddingBottom={false}>{title}</SectionHeader>

        <div>
          {canEditPlan && hasEdit === false && (
            <Button
              style={{ color: "var(--White)" }}
              onClick={() =>
                history.push(
                  `/ctpo/study-plans/${id}/${planId}/countries-and-site-groups#country-inputs`,
                  { tabIndex: tab },
                )
              }
            >
              <EditIcon style={{ marginRight: ".3em" }} /> Edit
            </Button>
          )}
          {hasEdit && (
            <IconButton onClick={() => setOpenEdit(true)}>
              <EditIcon classes={classes} />
            </IconButton>
          )}
          {hasDelete && (
            <IconButton onClick={() => setOpen(true)}>
              <DeleteIcon classes={classes} />
            </IconButton>
          )}
        </div>
      </HeaderContainer>
      {hasDelete && (
        <DeleteCountyModal
          countryId={countryId}
          siteGroup={siteGroup}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
      {hasEdit && openEdit && (
        <EditCountryModal
          countryId={countryId}
          siteGroup={siteGroup}
          studyPlanType={studyPlanType}
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          currentEffort={currentEffort}
          currentTimeframe={currentTimeframe}
        />
      )}
    </Container>
  );
};

export default CountryHeader;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--Primary-color);
  border-radius: 4px;
  width: 100%;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-block: 1.5em;
  align-items: center;
  border-radius: 4px;
  background-color: ${(p) =>
    p.variant === "primary" ? "var(--Primary-color)" : "#808285"};
  height: 48px;
  margin-bottom: 1em;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
  },
}));
