import React from "react";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import { isValidNumberStringValues } from "../../../../utils/helpers";

const Row = (props) => {
  const { row, name, subTableLength, cellWidth } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const cellClasses = useCellStyles();
  const cellClassesAlt = useCellStylesAlt();
  const cellHeader = useCellHeaderStyles();
  const iconStyles = useIconStyles();
  const cellHeaderAlt = useCellHeaderStylesAlt();

  return (
    <React.Fragment>
      <TableRow classes={classes}>
        <TableCell
          classes={cellHeader}
          component="th"
          scope="row"
        >
          <div style={{ width: "169px" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon classes={iconStyles} />
              ) : (
                <KeyboardArrowDownIcon classes={iconStyles} />
              )}
            </IconButton>
            {name}
          </div>
        </TableCell>
        {row?.header_totals.map((total, index) => (
          <TableCell
            key={`total-${index}`}
            align="right"
            classes={cellClasses}
            style={{ width: 81.83 }}
          >
            {!isValidNumberStringValues(total) ? total : Math.trunc(total)}
          </TableCell>
        ))}
      </TableRow>
      {open && (
        <TableRow>
          <TableCell
            style={{ padding: 0, width: "110px" }}
            colSpan={subTableLength}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
            >
              {row?.sub_table_body.map((row) => (
                <Table key={row?.country_name}>
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={cellWidth ? { width: cellWidth } : {}}
                        classes={cellHeaderAlt}
                        component="th"
                        scope="row"
                      >
                        <div style={!cellWidth ? { width: "169px" } : {}}>
                          {row?.country_name}
                        </div>
                      </TableCell>
                      {row?.data.map((data, index) => {
                        return (
                          <TableCell
                            key={`sub-row-cell-${index}`}
                            align="right"
                            classes={cellClassesAlt}
                          >
                            {!isValidNumberStringValues(data)
                              ? data
                              : Math.trunc(data)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default Row;

const useRowStyles = makeStyles((theme) => ({
  root: {
    background: "var(--White)",
  },
}));

const useCellStyles = makeStyles(() => ({
  root: {
    color: "black",
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: "0px",
    width: "81.83px",
    fontWeight: "bold",
    backgroundColor: "var(--Grey-3)",
  },
}));
const useCellStylesAlt = makeStyles(() => ({
  root: {
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: "0px",
    width: "80px",
    minWidth: "80px",
    maxWidth: "80px",
  },
}));

const useIconStyles = makeStyles(() => ({
  root: {
    color: "black",
  },
}));

const useCellHeaderStyles = makeStyles(() => ({
  root: {
    color: "black",
    width: "201px",
    position: "sticky",
    left: 0,
    fontWeight: "bold",
    backgroundColor: "var(--Grey-3)",
  },
}));

const useCellHeaderStylesAlt = makeStyles(() => ({
  root: {
    width: "201px",
    position: "sticky",
    left: 0,
    background: "#fff",
  },
}));
