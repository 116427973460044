import React from "react";
import styled from "styled-components";
import { useOktaAuth } from "@okta/okta-react";

const Goodbye = () => {
  const { oktaAuth } = useOktaAuth();
  const handleClick = () => oktaAuth.signInWithRedirect();

  return (
    <Container>
      <LogoutBox>
        <h2>You are Logged Out</h2>
        <span>Thank you for using Predict</span>
        <LogOutButton onClick={handleClick}>Sign in</LogOutButton>
      </LogoutBox>
    </Container>
  );
};

export default Goodbye;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 91px);
`;

const LogoutBox = styled.div`
  width: 500px;
  height: 300px;
  background-color: white;
  padding: 1em 2em;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: center;
  align-items: center;
`;

const LogOutButton = styled.button`
  border: 1px solid var(--White);
  color: var(--White);
  border-radius: 4px;
  padding: 1em;
  font-size: 1rem;
  background-color: var(--Primary-color);
  width: 100%;

  &:hover {
    background-color: var(--Primary-color);
    opacity: 0.8;
  }
`;
