export const HEAD_CELLS = [
  { id: "projectId", label: "Project ID", align: "left", minWidth: "7rem" },
  { id: "studyName", label: "Study Name", align: "left" },
  { id: "sponsorName", label: "Sponsor", align: "left" },
  { id: "opportunityStage", label: "Opportunity Stage", align: "left" },
  { id: "enrollmentPlanExists", label: "Enroll Plan", width: "5.5rem" },
  { id: "soeExists", label: "SOE", width: "5.5rem" },
  { id: "soeCosts", label: "SOE Costs", width: "5.5rem" },
  { id: "soeClinicalInformatics", label: "SOE Clin Strat", minWidth: "7rem" },
  { id: "soeDataManagement", label: "SOE DM", width: "5.5rem" },
  {
    id: "enrollmentPlanLastUpdate",
    label: "Enroll Plan Last Update",
    minWidth: "10rem",
  },
  { id: "soeLastUpdate", label: "SOE Last Update", minWidth: "6rem" },
];
