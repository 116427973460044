import { Check, Edit } from "@material-ui/icons";
import { IconButton, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";

import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";

export default function TableHeader() {
  const {
    seqCohortColumnHeaders,
    setSeqCohortColumnHeaders,
    setSeqCohortChangeLog,
  } = useStudyPlan();
  const [columnHeaderEdit, setColumnHeaderEdit] = useState({});

  const renderEditableColumnHeader = (name) => {
    return (
      <Cell
        width={`${(9 / 70) * 100}%`}
        variant="header"
      >
        {!columnHeaderEdit[name] && (
          <Typography
            style={{ flex: 1 }}
            variant="body2"
          >
            <strong>{seqCohortColumnHeaders[name]}</strong>
          </Typography>
        )}
        {columnHeaderEdit[name] && (
          <TextField
            style={{ flex: 1 }}
            value={seqCohortColumnHeaders[name]}
            onChange={(e) => {
              setSeqCohortColumnHeaders({
                ...seqCohortColumnHeaders,
                [name]: e.target.value,
              });
              setSeqCohortChangeLog({ someValue: "seq cohort change" });
            }}
            variant="outlined"
            size="small"
          />
        )}

        {
          <EditButtonContainer>
            <IconButton
              size="small"
              onClick={() => {
                setColumnHeaderEdit({
                  ...columnHeaderEdit,
                  [name]: !columnHeaderEdit[name],
                });
              }}
            >
              {!columnHeaderEdit[name] ? <Edit /> : <Check />}
            </IconButton>
          </EditButtonContainer>
        }
      </Cell>
    );
  };
  return (
    <>
      <Row>
        <Space width={`${(8 / 70) * 100}%`} />
        <Space width={`${(8 / 70) * 100}%`} />
        <Space width={`${(6 / 70) * 100}%`} />
        <Space width={`${(3 / 70) * 100}%`} />
        <Space width={`${(3 / 70) * 100}%`} />
        <Cell
          width={`${(6 / 70) * 100}%`}
          leftBorder
          variant="header"
        >
          <Typography variant="body2">
            <strong>Cohort Start</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(9 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Screening</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(9 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Enrolling</strong>
          </Typography>
        </Cell>
        {renderEditableColumnHeader("columnHeader3")}
        {renderEditableColumnHeader("columnHeader4")}
      </Row>
      <Row>
        <Cell
          width={`${(8 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Cohort Name</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(8 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Predecessor Cohort</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(6 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Predecessor Activity</strong>
          </Typography>{" "}
        </Cell>
        <Cell
          width={`${(3 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Enrolled Patients</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(3 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Lag</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(6 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Start Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
        <Cell
          width={`${(4 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Duration</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(5 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>End Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
        <Cell
          width={`${(4 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Duration</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(5 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>End Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
        <Cell
          width={`${(4 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Duration</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(5 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>End Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
        <Cell
          width={`${(4 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>Duration</strong>
          </Typography>
        </Cell>
        <Cell
          width={`${(5 / 70) * 100}%`}
          variant="header"
        >
          <Typography variant="body2">
            <strong>End Date</strong>
          </Typography>
        </Cell>
        {/* <Cell width={`${(2 / 70) * 100}%`} variant="header">
          <Typography variant="body2">
            <strong>Study Day</strong>
          </Typography>
        </Cell> */}
      </Row>
    </>
  );
}

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid var(--Grey-4)")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "var(--Grey-1)" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid var(--Grey-4);
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid var(--Grey-4)" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid var(--Grey-4)" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--Grey-4)" : "")};
`;

const Space = styled.div`
  height: 100%;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid transparent;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid transparent" : "none")};
  &:last-child {
    border: none;
  }
`;

const EditButtonContainer = styled.div`
  cursor: pointer;
  padding: 1px;
  border-radius: 50%;
`;
