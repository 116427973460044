import { LinearProgress } from "@material-ui/core";
import React, { useMemo } from "react";
import { Modal } from "../../../../app/components";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import PrimaryButton from "../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../app/components/buttons/secondary";

export const DeleteScheduleEventModal = ({
  deleteOpen,
  savingSOEStudy,
  setDeleteOpen,
  scheduleOfEventsId,
  redirect,
  id,
}) => {
  const { SOEError, deleteSOE, setSOEError } = useScheduleEvents();
  const { showSnackbar } = useSnackbar();

  const deleteMessage = useMemo(() => {
    if (SOEError)
      return "SOE cannot be deleted. Please contact the Creator of the SOE to delete.";
    return `
        Are you sure you want to delete this Schedule of Events record?
        This cannot be un-done and will remove all Visits, Assessments &
        Mappings at both the Study and Country level including all
        related Cost, Clinical Strategy and Data Management details.
        `;
  }, [SOEError]);

  const deleteSOEHandler = async () => {
    try {
      const success = await deleteSOE(scheduleOfEventsId, id);
      if (success) {
        setDeleteOpen(false);
        showSnackbar("Deleted successfully", "success");
        redirect();
      }
    } catch (err) {
      console.log(err);
      showSnackbar("Failed to delete soe", "error");
    }
  };

  const closeDeleteModal = () => {
    setDeleteOpen(false);
    setSOEError(false);
  };

  return (
    <Modal
      open={deleteOpen}
      onClose={closeDeleteModal}
      aria-labelledby="delete SOE"
      aria-describedby="delete a SOE"
      title="Delete SOE"
    >
      <Container>
        {savingSOEStudy ? (
          <LinearProgress />
        ) : (
          <Message>{deleteMessage}</Message>
        )}
      </Container>
      <SideBySide>
        <SecondaryButton
          autoFocus
          onClick={closeDeleteModal}
          disabled={savingSOEStudy}
        >
          {SOEError ? "Close" : "Cancel"}
        </SecondaryButton>
        {!SOEError && (
          <PrimaryButton
            disabled={savingSOEStudy}
            onClick={deleteSOEHandler}
          >
            DELETE
          </PrimaryButton>
        )}
      </SideBySide>
    </Modal>
  );
};

const Message = styled.p`
  text-align: center;
  padding-top: 0rem;
`;

const Container = styled.div`
  display: grid;
  padding: 1rem;
  grid-gap: 2em;
`;

const SideBySide = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
