import { useHistory, useParams } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React from "react";
import styled from "styled-components";
import SecondaryButton from "../../../../app/components/buttons/secondary";
import TertiaryButton from "../../../../app/components/buttons/tertiary";

const Controls = ({ errors }) => {
  const history = useHistory();
  const { id, scheduleId } = useParams();

  const handleBackToList = () => {
    history.push(`/ctpo/study-plans/${id}`);
  };

  const handleEdit = () => {
    history.push(`/ctpo/study-plans/${id}/soe/${scheduleId}`);
  };

  return (
    <Container>
      <TertiaryButton
        onClick={handleBackToList}
        //   disabled={saveLoading}
      >
        <ChevronLeftIcon />
        Back to SOE List
      </TertiaryButton>

      <ButtonContainer>
        <SecondaryButton onClick={handleEdit}>Edit SOE</SecondaryButton>
      </ButtonContainer>
    </Container>
  );
};

export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;
