import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { Modal } from "../../../../app/components";
import styled from "styled-components";
import API from "../../../../app/utils/api";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import SecondaryButton from "../../../../app/components/buttons/secondary";
import PrimaryButton from "../../../../app/components/buttons/primary";

export default function ImportCostModal({
  open,
  handleClose,
  studyId,
  scheduleId,
  siteGroupId,
}) {
  const [selectedCostType, setSelectedCostType] = useState("avg");
  const [countryAssessments, setCountryAssessments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCountryAssessments = async () => {
      setLoading(true);
      try {
        const response = await API.getCoutryCosts(scheduleId, siteGroupId);
        setCountryAssessments(response.data); // Assuming the API response contains the array of assessments
      } catch (error) {
        console.error("Error fetching country assessments:", error);
        setCountryAssessments([]); // Set an empty array in case of an error
      }
      setLoading(false);
    };

    if (open) {
      fetchCountryAssessments();
    }
  }, [open, scheduleId, siteGroupId]);

  const { showSnackbar } = useSnackbar();

  const resetModal = () => {
    setSelectedCostType("avg");
    handleClose();
  };
  const handleImport = async () => {
    const costTypeMapping = {
      max: "Max",
      min: "Min",
      avg: "Avg",
    };

    const payload = {
      studyId: studyId,
      scheduleOfEventsId: scheduleId,
      siteGroupId: siteGroupId,
      assessmentDefaultCostType: costTypeMapping[selectedCostType],
    };
    try {
      const response = await API.uploadCountryCosts(studyId, payload);
      if (response.status === 200) {
        showSnackbar("Uploaded successfully", "success");
        resetModal();
        window.location.reload();
      } else {
        showSnackbar("Failed to upload", "error");
      }
    } catch (error) {
      console.log(error);
      showSnackbar("Failed to upload", "error");
    }
  };

  const selectedCountryName = countryAssessments?.[0]?.countryName;
  return (
    <Modal
      open={open}
      onClose={resetModal}
      aria-labelledby={`Country Default Assessment Costs${
        selectedCountryName ? ` - ${selectedCountryName}` : ""
      }`}
      aria-describedby="Country Default Assessment Costs"
      title={`Country Default Assessment Costs${
        selectedCountryName ? ` - ${selectedCountryName}` : ""
      }`}
      sx={{ minWidth: "30rem", maxWidth: "100%", width: "70rem" }}
    >
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : countryAssessments.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <thead>
                  <tr>
                    <th>Assessment Type</th>
                    <th>Assessment Name</th>
                    <th
                      className={
                        selectedCostType === "max"
                          ? "selected-column"
                          : "unselected-column"
                      }
                    >
                      <CostTypeSelector>
                        <input
                          type="radio"
                          name="costType"
                          value="max"
                          checked={selectedCostType === "max"}
                          onChange={() => setSelectedCostType("max")}
                        />
                        Max Assessment Cost
                      </CostTypeSelector>
                    </th>
                    <th
                      className={
                        selectedCostType === "min"
                          ? "selected-column"
                          : "unselected-column"
                      }
                    >
                      <CostTypeSelector>
                        <input
                          type="radio"
                          name="costType"
                          value="min"
                          checked={selectedCostType === "min"}
                          onChange={() => setSelectedCostType("min")}
                        />
                        Min Assessment Cost
                      </CostTypeSelector>
                    </th>
                    <th
                      className={
                        selectedCostType === "avg"
                          ? "selected-column"
                          : "unselected-column"
                      }
                    >
                      <CostTypeSelector>
                        <input
                          type="radio"
                          name="costType"
                          value="avg"
                          checked={selectedCostType === "avg"}
                          onChange={() => setSelectedCostType("avg")}
                        />
                        Avg Assessment Cost
                      </CostTypeSelector>
                    </th>
                    <th>Count Of Data Points</th>
                  </tr>
                </thead>
                <tbody>
                  {countryAssessments.map((assessment) => (
                    <tr key={assessment.assessmentId}>
                      <td>{assessment.assessmentTypeDetail}</td>
                      <td>{assessment.assessmentName}</td>
                      <td>{assessment.assessmentCostMAX}</td>
                      <td>{assessment.assessmentCostMIN}</td>
                      <td>{assessment.assessmentCostAVG}</td>
                      <td>{assessment.countOfDataPoints}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <NoDataMessage>No Default Assessment Costs Available</NoDataMessage>
        )}
        <ButtonContainer>
          <SecondaryButton onClick={resetModal}>Cancel</SecondaryButton>
          {countryAssessments.length > 0 && (
            <PrimaryButton onClick={handleImport}>Import</PrimaryButton>
          )}
        </ButtonContainer>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const TableContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  background-color: white;
  padding-top: 0px;
  border: 1px solid var(--Grey-4);
  border-radius: 4px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.5em;
    border-right: 1px solid var(--Grey-4);
    border-bottom: 1px solid var(--Grey-4);
    text-align: center;
    font-size: 0.875rem;
  }

  th:last-child,
  td:last-child {
    border-right: none;
  }

  th {
    background-color: var(--Grey-1);
    font-weight: bold;
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  td {
    font-weight: 400;
  }

  th.selected-column {
    background-color: var(--Primary-color);
    color: white;
  }

  th.unselected-column {
    background-color: #e7f9f9;
    color: black;
    cursor: pointer;
  }

  th.unselected-column,
  th.unselected-column * {
    cursor: pointer;
  }

  th.unselected-column:hover {
    background-color: var(--Primary-color);
    color: black;
    cursor: pointer;
  }

  tr:last-child td {
    border-bottom: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const CostTypeSelector = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5em;

  input[type="radio"] {
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 1px solid var(--Primary-color);
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;

    &:checked {
      background-color: var(--Primary-color);
    }
  }

  span {
    font-size: 1em;
  }
`;

const NoDataMessage = styled.div`
  text-align: center;
  font-size: 1.2em;
  padding: 1em;
`;
