import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import {
  SpecialTextField,
  Tooltip,
  SectionHeader,
} from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import SeparateContainer from "../../../../../app/components/separate.Container";

const ScenarioB = ({ values, setValues, setFieldErrors }) => {
  const { studyPlan, updatePlan, isSequential } = useStudyPlan();

  const {
    screenFailRate,
    dropOutRate,
    screeningPeriodDuration,
    overallEnrollmentDuration,
    treatmentDuration,
    followUpDuration,
    parameters,
    milestones,
    pauseEnrollmentAfterPatient = null,
    pauseEnrollmentDays = null,
    cohortType,
    studyPlanTypeDetail,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = studyPlan.parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150,
  );

  const isNotSequentialCohort = useMemo(
    () => cohortType !== 2 && studyPlanTypeDetail !== "Cohort (D)",
    [cohortType, studyPlanTypeDetail],
  );

  const [PauseEnrollment, setPauseEnrollment] = useState({
    pauseEnrollmentAfterPatient: pauseEnrollmentAfterPatient,
    pauseEnrollmentDays: pauseEnrollmentDays,
  });

  const [afterShowEmptyError, setAfterShowEmptyError] = useState(false);
  const [daysShowEmptyError, setDaysShowEmptyError] = useState(false);

  const isValueEmpty = (value) => value === "" || value === null;

  const isRequired = (lowerBound, rate) =>
    isValueEmpty(lowerBound) && isValueEmpty(rate);

  const isNumberPatientsEnrolledOrScreenFaiLRateRequired = isRequired(
    number_patients_enrolled.lowerBound,
    screenFailRate,
  );

  const isPatientsCompletingTreatmentOrDropOutRateRequired = isRequired(
    number_patients_treatment.lowerBound,
    dropOutRate,
  );

  const handlePauseEnrollmentChange = (field, value) => {
    const sanitizedValue = value === null ? null : value;

    setPauseEnrollment({
      ...PauseEnrollment,
      [field]: sanitizedValue,
    });

    if (field === "pauseEnrollmentDays") {
      updatePlan("pauseEnrollmentDays", sanitizedValue);
    }

    if (field === "pauseEnrollmentAfterPatient") {
      updatePlan("pauseEnrollmentAfterPatient", sanitizedValue);
    }
  };

  const customValidationDefinition = useMemo(
    () => ({
      "overallenrollmentduration(days)": {
        range: [1, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      numberofactivatedsites: {
        range: [1, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      numberofpatientsscreened: {
        range: [1, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      "screeningperiodperpatient(days)": {
        range: [0, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      "treatmentperiodperpatient(days)": {
        range: [0, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      "follow-upperiodperpatient(days)": {
        range: [0, Infinity],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      "screenfailrate(indecimals)": {
        range: [0.0, 0.99],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: true,
      },
      "dropoutrate(indecimals)": {
        range: [0.0, 0.99],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: true,
      },
      numberofpatientsenrolled: {
        range: [1, subjects_to_screen.lowerBound],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      numbrofpatientscompletingtreatment: {
        range: [
          1,
          number_patients_enrolled.lowerBound || subjects_to_screen.lowerBound,
        ],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      numbrofpatientscompletingfollowup: {
        range: [
          1,
          number_patients_treatment.lowerBound ||
            number_patients_enrolled.lowerBound ||
            subjects_to_screen.lowerBound,
        ],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      pauseEnrollment: {
        range: [1, 9999],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
    }),
    [
      subjects_to_screen.lowerBound,
      number_patients_enrolled.lowerBound,
      number_patients_treatment.lowerBound,
      screenFailRate,
    ],
  );

  const handleChangeParam = (param, key, value) => {
    let numericValue;
    if (value === "" || value === null || value === undefined) {
      numericValue = value;
    } else {
      numericValue = isNaN(value) ? value : Number(value);
    }
    const index = parameters.map((param) => param.parameterName).indexOf(key);
    const new_params = [...parameters];

    new_params[index][param] = numericValue;

    updatePlan("parameters", new_params);
  };

  const handleChangeMilestones = (param, key, value) => {
    let numericValue;
    if (value === "" || value === null || value === undefined) {
      numericValue = value;
    } else {
      numericValue = isNaN(value) ? value : Number(value);
    }

    const index = milestones
      .map((milestone) => milestone.milestoneName)
      .indexOf(key);
    const new_milestones = [...milestones];
    new_milestones[index][param] = numericValue;
    updatePlan("milestones", new_milestones);
  };

  useEffect(() => {
    const { pauseEnrollmentDays, pauseEnrollmentAfterPatient } =
      PauseEnrollment;

    const isDaysEmpty = !pauseEnrollmentDays;
    const isAfterPatientEmpty = !pauseEnrollmentAfterPatient;

    const pauseEnrollmentAfterShouldShowEmptyError =
      pauseEnrollmentAfterPatient &&
      (pauseEnrollmentDays === "" || isDaysEmpty);

    const pauseEnrollmentDaysShouldDisablePauseEnrollment =
      pauseEnrollmentDays &&
      (pauseEnrollmentAfterPatient === "" || isAfterPatientEmpty);

    setAfterShowEmptyError(pauseEnrollmentDaysShouldDisablePauseEnrollment);
    setDaysShowEmptyError(pauseEnrollmentAfterShouldShowEmptyError);

    if (isDaysEmpty && isAfterPatientEmpty) {
      setFieldErrors((prevState) => {
        const { undefined: _, pauseEnrollmentError, ...rest } = prevState;
        return rest;
      });
    } else if (isDaysEmpty || isAfterPatientEmpty) {
      setFieldErrors((prevState) => ({
        ...prevState,
        pauseEnrollmentError: {
          ...prevState.pauseEnrollmentError,
          errors: ["Can't be empty."],
        },
      }));
    } else {
      setFieldErrors((prevState) => {
        const { undefined: _, pauseEnrollmentError, ...rest } = prevState;
        return rest;
      });
    }
  }, [
    PauseEnrollment.pauseEnrollmentDays,
    PauseEnrollment.pauseEnrollmentAfterPatient,
    setFieldErrors,
    PauseEnrollment,
  ]);

  useEffect(() => {
    if (isNumberPatientsEnrolledOrScreenFaiLRateRequired) {
      setFieldErrors((prevState) => ({
        ...prevState,
        requiredError: {
          ...prevState.requiredError,
          errors: ["Can't be empty."],
        },
      }));
    } else {
      setFieldErrors((prevState) => {
        const { requiredError, ...rest } = prevState;
        return rest;
      });
    }
  }, [screenFailRate, number_patients_enrolled.lowerBound]);

  useEffect(() => {
    if (isPatientsCompletingTreatmentOrDropOutRateRequired) {
      setFieldErrors((prevState) => ({
        ...prevState,
        dropTreatmentRequired: {
          ...prevState.dropTreatmentRequired,
          errors: ["Can't be empty."],
        },
      }));
    } else {
      setFieldErrors((prevState) => {
        const { dropTreatmentRequired, ...rest } = prevState;
        return rest;
      });
    }
  }, [dropOutRate, number_patients_treatment.lowerBound]);

  return (
    <Container>
      <TwoColumnLayout>
        <FlexCol>
          <SeparateContainer>
            <SectionHeader
              hasStartAdornment={false}
              marginBottom={false}
            >
              Overall Parameters
            </SectionHeader>
            <div style={{ padding: "20px" }}>
              {!isSequential && (
                <div style={{ padding: "0.5em" }}>
                  <SpecialTextField
                    label="Overall Enrollment Duration (days)"
                    value={overallEnrollmentDuration}
                    onChange={(value) => {
                      updatePlan("overallEnrollmentDuration", value);
                    }}
                    required
                    showArrows={false}
                    customValidation={
                      customValidationDefinition[
                        "overallenrollmentduration(days)"
                      ]
                    }
                    setFieldErrors={setFieldErrors}
                  />
                </div>
              )}
              <div style={{ padding: "0.5em" }}>
                <SpecialTextField
                  setFieldErrors={setFieldErrors}
                  label={sites_to_activate.parameterDescr}
                  value={sites_to_activate.lowerBound}
                  onChange={(value) => {
                    handleChangeParam(
                      "lowerBound",
                      sites_to_activate.parameterName,
                      value,
                    );
                    setValues({ ...values, updated: new Date().getTime() });
                  }}
                  key={sites_to_activate.parameterName}
                  required
                  showArrows={false}
                  customValidation={
                    customValidationDefinition["numberofactivatedsites"]
                  }
                />
              </div>
            </div>
          </SeparateContainer>
          <SeparateContainer>
            <div>
              <SectionHeader
                hasStartAdornment={false}
                marginBottom={false}
              >
                Per Patient Duration from SOE
              </SectionHeader>
            </div>
            <div style={{ padding: "20px" }}>
              <div style={{ paddingInline: "0.5em" }}>
                <SpecialTextField
                  setFieldErrors={setFieldErrors}
                  label="Screening Period Per Patient (days)"
                  value={screeningPeriodDuration}
                  onChange={(value) =>
                    updatePlan("screeningPeriodDuration", value)
                  }
                  required
                  showArrows={false}
                  customValidation={
                    customValidationDefinition[
                      "screeningperiodperpatient(days)"
                    ]
                  }
                />
              </div>
              <div style={{ padding: "0.5em" }}>
                <SpecialTextField
                  setFieldErrors={setFieldErrors}
                  label="Treatment Period Per Patient (days)"
                  value={treatmentDuration}
                  onChange={(value) => updatePlan("treatmentDuration", value)}
                  required
                  showArrows={false}
                  customValidation={
                    customValidationDefinition[
                      "treatmentperiodperpatient(days)"
                    ]
                  }
                />
              </div>
              <div style={{ padding: "0.5em" }}>
                <SpecialTextField
                  setFieldErrors={setFieldErrors}
                  label="Follow-Up Period Per Patient (days)"
                  value={followUpDuration}
                  onChange={(value) => updatePlan("followUpDuration", value)}
                  endadornment={
                    <Tooltip info="If applicable to protocol. Make sure to also complete the Follow-Up Period Per Patients (days) field." />
                  }
                  showArrows={false}
                  customValidation={
                    customValidationDefinition[
                      "follow-upperiodperpatient(days)"
                    ]
                  }
                />
              </div>
            </div>
          </SeparateContainer>
          {isNotSequentialCohort && (
            <SeparateContainer>
              <div>
                <SectionHeader
                  hasStartAdornment={false}
                  marginBottom={false}
                  endAdornment={
                    <Tooltip info="Complete only if a pause in enrollment is expected. For example, if an Interim Analysis is needed after the first 100 patients completes three months of treatment before proceeding, include 100 in the first field and then calculate when screening would resume in the second (consider the treatment time for the 100th patient plus time for the associated data entry and completing and reviewing the interim analysis). Reminder: the second field is when patient screening (NOT enrollment) resumes." />
                  }
                >
                  Screening/Enrollment Pause (if applicable)
                </SectionHeader>
              </div>
              <div style={{ padding: "20px" }}>
                <div style={{ padding: "0.5em" }}>
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    label="Pause Enrollment After Patient #"
                    value={PauseEnrollment.pauseEnrollmentAfterPatient}
                    showCustomError={afterShowEmptyError}
                    customMessageError={"Can't be empty."}
                    required={
                      PauseEnrollment.pauseEnrollmentDays !== "" &&
                      PauseEnrollment.pauseEnrollmentDays !== null
                    }
                    showTooltip={
                      PauseEnrollment.pauseEnrollmentDays ? true : false
                    }
                    showArrows={false}
                    customValidation={
                      customValidationDefinition["pauseEnrollment"]
                    }
                    onChange={(value) => {
                      handlePauseEnrollmentChange(
                        "pauseEnrollmentAfterPatient",
                        value,
                      );
                    }}
                  />
                </div>
                <div style={{ padding: "0.5em" }}>
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    label="Resume Screening After (days)"
                    showCustomError={daysShowEmptyError}
                    customMessageError={"Can't be empty."}
                    value={PauseEnrollment.pauseEnrollmentDays}
                    showTooltip={
                      PauseEnrollment.pauseEnrollmentAfterPatient ? true : false
                    }
                    showArrows={false}
                    required={
                      PauseEnrollment.pauseEnrollmentAfterPatient !== "" &&
                      PauseEnrollment.pauseEnrollmentAfterPatient !== null
                    }
                    customValidation={
                      customValidationDefinition["pauseEnrollment"]
                    }
                    onChange={(value) => {
                      handlePauseEnrollmentChange("pauseEnrollmentDays", value);
                    }}
                  />
                </div>
              </div>
            </SeparateContainer>
          )}
        </FlexCol>
        <FlexCol>
          <SeparateContainer>
            <SectionHeader
              hasStartAdornment={false}
              marginBottom={false}
            >
              Patient Numbers
            </SectionHeader>
            <div style={{ padding: "20px" }}>
              <div style={{ padding: "0.5em" }}>
                <SpecialTextField
                  setFieldErrors={setFieldErrors}
                  label={subjects_to_screen.parameterDescr}
                  value={subjects_to_screen.lowerBound}
                  onChange={(value) => {
                    handleChangeParam(
                      "lowerBound",
                      subjects_to_screen.parameterName,
                      value,
                    );
                    setValues({ ...values, updated: new Date().getTime() });
                  }}
                  key={subjects_to_screen.parameterName}
                  required
                  showArrows={false}
                  customValidation={
                    customValidationDefinition["numberofpatientsscreened"]
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  background: "#f3f4f4",
                  padding: "0.5em",
                  border: "1px solid #f0f0f0",
                  borderRadius: "4px",
                  marginBlock: "0.5em",
                }}
              >
                <div style={{ flex: 1 }}>
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    label="Number of Patients Enrolled"
                    value={number_patients_enrolled.lowerBound}
                    onChange={(value) => {
                      handleChangeMilestones(
                        "lowerBound",
                        number_patients_enrolled.milestoneName,
                        value,
                      );
                      setValues({ ...values, updated: new Date().getTime() });
                    }}
                    required={screenFailRate === null || screenFailRate === ""}
                    showCustomError={
                      isNumberPatientsEnrolledOrScreenFaiLRateRequired
                    }
                    customMessageError={"Can't be empty."}
                    disabled={
                      typeof screenFailRate === "number" ||
                      typeof screenFailRate === "string"
                    }
                    key={number_patients_enrolled.milestoneName}
                    showArrows={false}
                    customValidation={
                      customValidationDefinition["numberofpatientsenrolled"]
                    }
                  />
                </div>
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    label="Screen Fail Rate (in decimals)"
                    value={screenFailRate}
                    disabled={
                      typeof number_patients_enrolled.lowerBound === "number" ||
                      typeof number_patients_enrolled.lowerBound === "string"
                    }
                    required={
                      number_patients_enrolled.lowerBound === "" ||
                      number_patients_enrolled.lowerBound === null
                    }
                    onChange={(value) => updatePlan("screenFailRate", value)}
                    inputProps={{ step: 0.01 }}
                    min={0.01}
                    showCustomError={
                      isNumberPatientsEnrolledOrScreenFaiLRateRequired
                    }
                    customMessageError={"Can't be empty."}
                    showArrows={false}
                    customValidation={
                      customValidationDefinition["screenfailrate(indecimals)"]
                    }
                    endadornment={
                      <Tooltip
                        zIndex={10000}
                        info="Either the Screen Fail Rate OR the Number of Patients Enrolled must be completed here."
                      />
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  background: "#f3f4f4",
                  padding: "0.5em",
                  border: "1px solid #f0f0f0",
                  borderRadius: "4px",
                  marginBlock: "0.5em",
                }}
              >
                <div style={{ flex: 1 }}>
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    key={number_patients_treatment.milestoneId}
                    label={number_patients_treatment.milestoneLabel}
                    value={number_patients_treatment.lowerBound}
                    onChange={(value) => {
                      handleChangeMilestones(
                        "lowerBound",
                        number_patients_treatment.milestoneName,
                        value,
                      );
                      setValues({ ...values, updated: new Date().getTime() });
                    }}
                    disabled={
                      typeof dropOutRate === "number" ||
                      typeof dropOutRate === "string"
                    }
                    required={dropOutRate === null || dropOutRate === ""}
                    showArrows={false}
                    customMessageError={"Can't be empty."}
                    showCustomError={
                      isPatientsCompletingTreatmentOrDropOutRateRequired
                    }
                    customValidation={
                      customValidationDefinition[
                        "numbrofpatientscompletingtreatment"
                      ]
                    }
                  />
                </div>
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    label="Drop Out Rate (in decimals)"
                    value={dropOutRate}
                    disabled={
                      typeof number_patients_treatment.lowerBound ===
                        "number" ||
                      typeof number_patients_treatment.lowerBound === "string"
                    }
                    onChange={(value) => updatePlan("dropOutRate", value)}
                    inputProps={{ step: 0.01 }}
                    min={0.01}
                    showArrows={false}
                    showCustomError={
                      isPatientsCompletingTreatmentOrDropOutRateRequired
                    }
                    required={
                      number_patients_treatment.lowerBound === "" ||
                      number_patients_treatment.lowerBound === null
                    }
                    customMessageError={"Can't be empty."}
                    customValidation={
                      customValidationDefinition["dropoutrate(indecimals)"]
                    }
                    endadornment={
                      <Tooltip info="Either the Drop Out Rate OR the Number of Patients Completing Treatment must be completed here." />
                    }
                  />
                </div>
              </div>
              <div style={{ padding: "0.5em" }}>
                <SpecialTextField
                  setFieldErrors={setFieldErrors}
                  key={number_patients_follow_up.milestoneId}
                  label={number_patients_follow_up.milestoneLabel}
                  value={number_patients_follow_up.lowerBound}
                  onChange={(value) => {
                    handleChangeMilestones(
                      "lowerBound",
                      number_patients_follow_up.milestoneName,
                      value,
                    );
                    setValues({ ...values, updated: new Date().getTime() });
                  }}
                  endadornment={
                    <Tooltip
                      info={
                        MILESTONE_INFO[number_patients_follow_up.milestoneName]
                      }
                    />
                  }
                  showArrows={false}
                  customValidation={
                    customValidationDefinition[
                      "numbrofpatientscompletingfollowup"
                    ]
                  }
                />
              </div>
            </div>
          </SeparateContainer>
        </FlexCol>
      </TwoColumnLayout>
    </Container>
  );
};

export default ScenarioB;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5em;
`;
const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
  margin-top: 1em;
`;
const MILESTONE_INFO = {
  NUM_SUBJECTS_ENROLLED:
    "If a certain number of enrolled patients is required in a specific country, also complete this at the country level. Either the Screen Fail Rate OR the Number of Patients Enrolled must be completed here or at the country level.",
  NUM_SUBJECTS_COMPLETED:
    "If applicable to protocol. Make sure to also complete the Number of Patients Completing Follow-up field.",
};

const FlexCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
