import { LinearProgress, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import API from "../../app/utils/api";
import { Modal } from "../../app/components";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ExportTablesButton } from "./SiteListModalComponents/ExportTablesButton";
import { CloseButton } from "./SiteListModalComponents/CloseButton";
import { SiteListTable } from "./SiteListModalComponents/SiteListTable";

/*
  This component is a modal that displays the sites included and not included in a remodel.
  It uses the API to fetch the sites included and not included in a remodel.
  The parent component must pass a boolean value to control the modal.
  The parent component must pass a callback function to control the modal.
*/
export default function ViewSiteListModal({
  remodelViewOpen,
  setRemodelViewOpen,
}) {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [remodelSitesIncludedClosed, setRemodelSitesIncludedClosed] = useState(
    [],
  );
  const [remodelSitesIncluded, setRemodelSitesIncluded] = useState([]);
  const [remodelSitesNotIncluded, setRemodelSitesNotIncluded] = useState([]);
  const [remodelSitesFilteredNotIncluded, setRemodelSitesFilteredNotIncluded] =
    useState([]);
  const [remodelSitesFilteredIncluded, setRemodelSitesFilteredIncluded] =
    useState([]);
  const [
    remodelSitesFilteredIncludedClosed,
    setRemodelSitesFilteredIncludedClosed,
  ] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageIncluded, setPageIncluded] = React.useState(0);
  const [pageIncludedClosed, setPageIncludedClosed] = React.useState(0);
  const [pageNotIncluded, setPageNotIncluded] = React.useState(0);
  const [filterActive, setFilterActive] = useState(false);
  const [filters, setFilters] = useState({});

  const { id } = useParams();

  useEffect(() => {
    if (
      remodelViewOpen &&
      (remodelSitesIncluded.length === 0 ||
        remodelSitesNotIncluded.length === 0 ||
        remodelSitesIncludedClosed.length === 0)
    ) {
      (async () => {
        setLoading(true);
        try {
          const { data: sitesIncluded } = await API.sitesIncludedInRemodel(id);
          const { data: sitesNotIncluded } =
            await API.sitesNotIncludedInRemodel(id);

          const fieldsToCheck = [
            "Central / Local",
            "Country",
            "Site Activated - Actual",
            "Site Activated - Forecast",
            "Site Number - PI",
            "Site Selected Date",
            "Site Status",
          ];

          const filteredSitesIncludedClosed = sitesIncluded.filter((site) => {
            return (
              !fieldsToCheck.every((field) => site[field] === null) &&
              site["Site Status"] === "Closed"
            );
          });
          const filteredSitesIncluded = sitesIncluded.filter((site) => {
            return (
              !fieldsToCheck.every((field) => site[field] === null) &&
              site["Site Status"] !== "Closed"
            );
          });
          const filteredSitesNotIncluded = sitesNotIncluded.filter((site) => {
            return !fieldsToCheck.every((field) => site[field] === null);
          });

          setRemodelSitesIncludedClosed(filteredSitesIncludedClosed);
          setRemodelSitesIncluded(filteredSitesIncluded);
          setRemodelSitesNotIncluded(filteredSitesNotIncluded);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, remodelViewOpen]);

  const debounceFilter = useMemo(
    () =>
      debounce((lFilters) => {
        const filterKeys = Object.keys(lFilters);
        if (!filterKeys.length) {
          setRemodelSitesFilteredIncluded(
            remodelSitesIncluded.filter(
              (site) => site["Site Status"] !== "Closed",
            ),
          );

          setRemodelSitesFilteredIncludedClosed(
            remodelSitesIncludedClosed.filter(
              (site) => site["Site Status"] === "Closed",
            ),
          );
          setRemodelSitesFilteredNotIncluded(remodelSitesNotIncluded);
          return;
        }

        const filterData = (dataSet) =>
          dataSet.filter((project) => {
            return filterKeys.every((filterId) => {
              const { value, type, start, end } = lFilters[filterId];
              if (value === "") return true;

              if (type === "date") {
                if (!start || !end) return true;
                const startDate = moment(start).valueOf();
                const endDate = moment(end).valueOf();
                if (!project[filterId]) return false;

                const projectDate = moment(project[filterId]).valueOf();
                let startD = new Date(startDate);
                let endD = new Date(endDate);
                let projectD = new Date(projectDate);

                startD = new Date(
                  startD.getFullYear(),
                  startD.getMonth(),
                  startD.getDate(),
                );
                endD = new Date(
                  endD.getFullYear(),
                  endD.getMonth(),
                  endD.getDate(),
                );
                projectD = new Date(
                  projectD.getFullYear(),
                  projectD.getMonth(),
                  projectD.getDate(),
                );

                return projectD >= startD && projectD <= endD;
              }

              return project[filterId]
                ? project[filterId].toLowerCase().includes(value.toLowerCase())
                : false;
            });
          });

        const filteredClosedIncluded = filterData(remodelSitesIncludedClosed);
        const filteredIncluded = filterData(remodelSitesIncluded);
        const filteredNotIncluded = filterData(remodelSitesNotIncluded);

        setRemodelSitesFilteredIncluded(
          filteredIncluded.filter((site) => site["Site Status"] !== "Closed"),
        );

        setRemodelSitesFilteredIncludedClosed(
          filteredClosedIncluded.filter(
            (site) => site["Site Status"] === "Closed",
          ),
        );
        setRemodelSitesFilteredNotIncluded(filteredNotIncluded);
        setPageIncluded(0);
        setPageNotIncluded(0);
        setPageIncludedClosed(0);
      }, 500),
    [remodelSitesIncluded, remodelSitesNotIncluded, remodelSitesIncludedClosed],
  );

  useEffect(() => {
    debounceFilter(filters);
  }, [debounceFilter, filters]);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const rows = parseInt(value);

    setRowsPerPage(rows);
    setPageIncluded(0);
    setPageNotIncluded(0);
    setPageIncludedClosed(0);
  };

  const handleChangePageSitesIncluded = (event, newPage) => {
    setPageIncluded(newPage);
  };

  const handleChangePageSitesNotIncluded = (event, newPage) => {
    setPageNotIncluded(newPage);
  };

  const handleChangePageIncludedClosed = (event, newPage) => {
    setPageIncludedClosed(newPage);
  };

  const tabData = [
    { label: "Sites Included", id: "sites_included_in_remodel" },
    { label: "CLOSED SITES", id: "closed_sites_in_remodel" },
    { label: "Sites Not Included", id: "sites_not_included_in_remodel" },
  ];

  return (
    <Modal
      open={remodelViewOpen}
      onClose={() => setRemodelViewOpen(false)}
      aria-labelledby="Remodel View Site List"
      aria-describedby="Remodel View Site List"
      title="Remodel View Site List"
      root={{ padding: "0.5em 1em" }}
      sx={{
        width: loading ? "fit-content" : "90vw",
        height: "fit-content",
        top: loading ? "45%" : "50%",
      }}
    >
      <div style={{ width: "100%" }}>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LinearProgress style={{ width: "350px" }} />
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tabs
                value={tabValue}
                onChange={(_, value) => setTabValue(value)}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "var(--Primary-color)",
                  },
                }}
              >
                {tabData.map((tab) => (
                  <Tab
                    key={tab.id}
                    label={tab.label}
                    id={tab.id}
                  />
                ))}
              </Tabs>
            </div>
            <TablesContainer>
              {tabValue === 0 && (
                <SiteListTable
                  rows={remodelSitesFilteredIncluded}
                  order={order}
                  orderBy={orderBy}
                  handleRequestSort={handleRequestSort}
                  filterActive={filterActive}
                  setFilterActive={setFilterActive}
                  filters={filters}
                  setFilters={setFilters}
                  page={pageIncluded}
                  rowsPerPage={rowsPerPage}
                  count={remodelSitesIncluded.length || 0}
                  onChangePage={handleChangePageSitesIncluded}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  stableSort={stableSort}
                />
              )}
              {tabValue === 1 && (
                <SiteListTable
                  rows={remodelSitesFilteredIncludedClosed}
                  order={order}
                  orderBy={orderBy}
                  handleRequestSort={handleRequestSort}
                  filterActive={filterActive}
                  setFilterActive={setFilterActive}
                  filters={filters}
                  setFilters={setFilters}
                  page={pageIncludedClosed}
                  rowsPerPage={rowsPerPage}
                  count={remodelSitesIncludedClosed.length || 0}
                  onChangePage={handleChangePageIncludedClosed}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  showInfo
                  stableSort={stableSort}
                />
              )}
              {tabValue === 2 && (
                <SiteListTable
                  rows={remodelSitesFilteredNotIncluded}
                  order={order}
                  orderBy={orderBy}
                  handleRequestSort={handleRequestSort}
                  filterActive={filterActive}
                  setFilterActive={setFilterActive}
                  filters={filters}
                  setFilters={setFilters}
                  page={pageNotIncluded}
                  rowsPerPage={rowsPerPage}
                  count={remodelSitesNotIncluded.length || 0}
                  onChangePage={handleChangePageSitesNotIncluded}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  stableSort={stableSort}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  gap: "1rem",
                }}
              >
                <ExportTablesButton
                  setRemodelViewOpen={setRemodelViewOpen}
                  stableSort={stableSort}
                  remodelSitesFilteredIncluded={remodelSitesFilteredIncluded}
                  remodelSitesFilteredIncludedClosed={
                    remodelSitesFilteredIncludedClosed
                  }
                  remodelSitesFilteredNotIncluded={
                    remodelSitesFilteredNotIncluded
                  }
                  remodelSitesIncluded={remodelSitesIncluded}
                  remodelSitesIncludedClosed={remodelSitesIncludedClosed}
                  remodelSitesNotIncluded={remodelSitesNotIncluded}
                  order={order}
                  orderBy={orderBy}
                />
                <CloseButton setRemodelViewOpen={setRemodelViewOpen} />
              </div>
            </TablesContainer>
          </div>
        )}
      </div>
    </Modal>
  );
}

const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
