import React, { useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Row from "../../simulation/tables/details.table/row";
import { isValidNumberStringValues } from "../../utils/helpers";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import SeparateContainer from "../../../../app/components/separate.Container";

export default function SummaryTable({ summary }) {
  const classes = useStyles();
  const rowClasses = useRowStyles();
  const headerClasses = useHeaderStyles();
  const cellHeaderClasses = useCellHeaderStyles();
  const tableClasses = useTableStyles();
  const cellClasses = useCellStyles();
  const { setFormattedForecastSummary } = useScheduleEvents();
  const COLUMN_HEADERS = [
    // "Country Name",
    "Site Id",
    "Site Count",
    "Subject Count",
    "Visit Count",
    "Assessment Count",
    "Visit Overhead",
    "Assessment Cost",
    "Exclude Cost",
    "Site Cost",
    "Total Cost",
  ];

  const [countrySummaries, total] = useMemo(() => {
    const cSummaries = {};
    let t = null;

    if (!summary) return [cSummaries, t];

    summary.forEach((s) => {
      const {
        siteId,
        siteCount,
        subjectCount,
        visitCount,
        assessmentCount,
        visitCost,
        assessmentCost,
        excludeCost,
        siteCost,
        totalCost,
      } = s;

      if (s.siteGroupName) {
        if (!cSummaries[s.siteGroupName])
          cSummaries[s.siteGroupName] = { details: [], subTotal: null };
        if (s.dataSet === "SubTotal")
          cSummaries[s.siteGroupName].subTotal = [
            siteId,
            siteCount ?? "",
            subjectCount ?? "",
            visitCount ?? "",
            assessmentCount ?? "",
            visitCost ?? "",
            assessmentCost ?? "",
            excludeCost ?? "",
            siteCost ?? "",
            totalCost ?? "",
          ];
        if (s.dataSet === "Detail")
          cSummaries[s.siteGroupName].details.push({
            country_name: "Detail",
            data: [
              siteId ?? "",
              siteCount ?? "",
              subjectCount ?? "",
              visitCount ?? "",
              assessmentCount ?? "",
              visitCost ?? "",
              assessmentCost ?? "",
              excludeCost ?? "",
              siteCost ?? "",
              totalCost ?? "",
            ],
          });
      }
      if (s.dataSet === "Total")
        t = [
          siteId,
          siteCount ?? "",
          subjectCount ?? "",
          visitCount ?? "",
          assessmentCount ?? "",
          visitCost ?? "",
          assessmentCost ?? "",
          excludeCost ?? "",
          siteCost ?? "",
          totalCost ?? "",
        ];
    });
    setFormattedForecastSummary({ cSummaries, t });
    return [cSummaries, t];
  }, [setFormattedForecastSummary, summary]);

  const renderCountryTables = () => {
    return Object.keys(countrySummaries).map((country) => {
      return (
        <Row
          cellWidth="125px"
          name={country}
          row={{
            header_totals: countrySummaries[country].subTotal,
            sub_table_body: countrySummaries[country].details,
          }}
          subTableLength={COLUMN_HEADERS.length + 1}
        />
      );
    });
  };
  return (
    <Container>
      <SeparateContainer>
        <Typography className={classes.heading}>Cost Summary</Typography>
        <TableContainer
          style={{ padding: "10px 20px 20px 20px" }}
          component={Paper}
        >
          {Object.keys(countrySummaries).length ? (
            <Table
              aria-label="collapsible table"
              classes={tableClasses}
              style={{ border: "1px solid var(--Grey-4)", width: "100%" }}
            >
              <TableHead classes={rowClasses}>
                <TableRow style={{ height: "112px" }}>
                  <TableCell classes={cellHeaderClasses}></TableCell>
                  {COLUMN_HEADERS?.map((header) => (
                    <TableCell
                      style={{ width: "133.5px" }}
                      key={header}
                      align="right"
                      classes={headerClasses}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderCountryTables()}
                <TableRow classes={rowClasses}>
                  <TableCell
                    classes={cellHeaderClasses}
                    component="th"
                    scope="row"
                  >
                    <div>Total</div>
                  </TableCell>
                  {total?.map((t, index) => (
                    <TableCell
                      key={`t-${index}`}
                      align="right"
                      classes={cellClasses}
                    >
                      {!isValidNumberStringValues(t) ? t : Math.trunc(t)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            "No Results Found"
          )}
        </TableContainer>
      </SeparateContainer>
    </Container>
  );
}

const Container = styled.div`
  overflow-x: auto;
`;

const useCellStyles = makeStyles(() => ({
  root: {
    color: "#333",
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: "0px",
    width: "81.83px",
  },
}));

const useRowStyles = makeStyles((theme) => ({
  root: {
    background: "var(--Grey-1)",
  },
}));

const useHeaderStyles = makeStyles(() => ({
  root: {
    fontWeight: 600,
    textAlign: "center",
    whiteSpace: "nowrap",
    transformOrigin: "50% 50%",
    // transform: "rotate(270deg)",
    padding: "0px",
  },
}));

const useCellHeaderStyles = makeStyles(() => ({
  root: {
    width: "201px",
    position: "sticky",
    left: 0,
    background: "var(--Grey-1)",
    zIndex: 10,
  },
}));

const useTableStyles = makeStyles(() => ({
  root: {
    width: "max-content",
  },
}));

const useStyles = makeStyles((theme) => ({
  heading: {
    color: "var(--White)",
    background: "var(--Primary-color)",
    fontSize: "1.2rem",
    fontWeight: "600",
    padding: "0.5rem",
    borderRadius: "4px",
    marginBottom: "1rem",
  },
  root: {
    padding: 0,
  },
}));
