import React, { useState } from "react";
import styled from "styled-components";
import ExcelExportsModal from "./excel.exports.modal";
import SecondaryButton from "../../../../app/components/buttons/secondary";

export default function ExcelExports() {
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <SecondaryButton onClick={() => setOpen(true)}>
        Export Tables
      </SecondaryButton>
      <ExcelExportsModal
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
`;
